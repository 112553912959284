.deleteModal{
    border-radius: 10px;
    .deleteModalHeader{
        border: none!important;
        padding: 1rem 1rem 1px!important;
    };
};

.yesButton{
    height: 40px;
    width:  65px;
    background-color: #F7B217 !important;
    color: white !important;
}