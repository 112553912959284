* {
  font-family: "Inter", sans-serif;
  letter-spacing: 0px;
}

.modalDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 1rem 0rem;

  .modalTitle {
    font-size: 1.1rem;
    font-weight: bold;
  }

  .modalCross {
    // border: 1px solid gray;
    border-radius: 100%;
    padding: 0 0.7rem 0.3rem;
    background-color: #f4f5f7;
    margin-bottom: 0.8rem;
    cursor: pointer;
  }
}

.gridModal {
  // display: grid;
  //   grid-template-columns: repeat(1, 1fr);
  //   grid-template-rows: 100px;
  //   grid-auto-rows: 100px;
  //   grid-gap: 20px;
  .select {
    width: 100%;
    // padding: 0.5rem 2rem 0.5rem 1rem;
    border-radius: 5px;
    border-color: #dddddd;
    height: 38px;
  }

  .labelFont {
    font-size: 0.9rem;
  }

  .inputFont {
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 7px 5px 7px 5px;
  }
}

.buttonDiv {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
