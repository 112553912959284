* {
  font-family: "Inter", sans-serif;
  letter-spacing: 0px;
  font-size: 14px;
}

.modalDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 1rem 0rem;

  .modalTitle {
    font-weight: bold;
  }

  .modalCross {
    // border: 1px solid gray;
    border-radius: 100%;
    padding: 0 0.7rem 0.3rem;
    background-color: #f4f5f7;
    margin-bottom: 0.8rem;
    cursor: pointer;
  }
}
.selectOneWay {
  .selectDiv {
    .select {
      width: 100%;
      border-radius: 5px;
      height: 38px;
      border: 1px solid #e3e6ef;
    }
  }
}
.gridModal {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 75px;
  grid-auto-rows: 100px;
  grid-gap: 10px;
  @media (min-width: 280px) and (max-width: 320px) {
    grid-template-columns: repeat(1, 1fr);
  }
  .labelFont {
    font-size: 0.9rem;
  }
  .inputFont {
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 0;
  }
}

.buttonDiv {
  .sendLink {
    background-color: #f7b217;
    color: white;
    :hover {
      background-color: rgb(238, 255, 0);
      color: #272b41;
    }
  }

  .sendCash {
    background-color: #20c997;
    color: white;
  }
}

.oneWayRoundWayButtonDiv {
  border: 0.1px solid #e3e6ef;
  border-radius: 5px;
  gap: 20px;
  display: flex;
}

.btnOne {
  border: none;
  border-radius: 5px;
  padding: 0.3rem 0.8rem 0.3rem 0.8rem;
  background: white;
}
.btnRound {
  border: none;
  border-radius: 5px;
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
  margin-left: -0.6rem;
  background-color: white;
  // opacity: 100;
}

.input {
  height: 38px;
  width: 100%;
  border: 1px solid #e3e6ef;
  border-radius: 5px;
  padding: 0 0 0 1rem;
  font-size: 14px;
}

.rountTripVehicleNDays {
  display: flex;
  justify-content: space-between;
  height: 70px;
  gap: 10px;
  @media (min-width: 280px) and (max-width: 320px) {
    flex-direction: column;
    height: auto;
  }
  .vehiclTypeDiv {
    display: flex;
    flex-direction: column;
    width: 50%;
    @media (min-width: 280px) and (max-width: 320px) {
      width: 100%;
    }
    .vehilceSelect {
      height: 38px;
      border-radius: 5px;
      border-color: #dddddd;
      border: 1px solid #e3e6ef;
    }
  }
  .noOfDays {
    display: flex;
    flex-direction: column;
    width: 50%;
    @media (min-width: 280px) and (max-width: 320px) {
      width:100%;
    }
    .noOfDaysInput {
      height: 38px;
      border: 1px solid #e3e6ef;
      border-radius: 5px;
      padding: 0 0 0 1rem;
      font-size: 14px;
    }
  }
}

.popupcontent {
  width: 320px !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 70px 10px rgb(0, 0, 0/11%);
  border-radius: 10px;
  text-align: center;
  border-color: none;

  .imgbox {
    text-align: center;

    .CheckedIcon {
      width: 96px;
      height: 96px;
      margin-top: 20px;
    }
  }

  .sucessmessage {
    margin-top: 30px;
    color: #5a5f7d;
    font-size: 14px;
    font-weight: 400;
  }

  .dashboard {
    margin-top: 5px;
    font-size: 14px;
    color: #5a5f7d;
    font-weight: 400;
  }

  .button {
    border: none;
    margin-top: 15px;
    background-color: #f7b217;
    border-radius: 8px;
    width: 213px;
    height: 49px;
    font-size: 14px;
    color: #ffffff;
  }
}
.suggestionContainer{
  position: relative;

.addressSearchRoundTrip{
  background-color: rgba(88, 88, 88, 0.953);
  position: absolute;
  bottom:40px;
  left:0px;
  width: 100%;
  border-radius: 5%;
  z-index: 999;

  > div {
    @media (min-width: 320px) and (max-width: 767px) {
      font-size: 10px !important;
      cursor: pointer;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      font-size: 10px !important;
      cursor: pointer;
    }
  }
}
.addressSearchOnWay {
  background-color: rgba(88, 88, 88, 0.932);
  position: absolute;
  bottom:50px;
  left:0px;
  width: 100%;
  border-radius: 5%;
  z-index: 999;

  > div {
    @media (min-width: 320px) and (max-width: 767px) {
      font-size: 10px !important;
      cursor: pointer;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      font-size: 10px !important;
      cursor: pointer;
    }
  }
}
}

.sendLink:disabled{
  opacity: 0.5 !important;
  background-color: #f7b217 !important;
  color: white !important;
};

.muiInputColor:focus{
  outline: none;
  border-color: transparent;
};

// .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
//   height: 34px !important;
// }

.pickUpTime > div div{
   height: 34px;
};

.pickUpTime > div div input:focus{
  box-shadow: none !important;
};

// box-shadow: none !important;