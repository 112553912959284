.logincomponent {
    @media screen and (min-width: 1024px) {
        margin-left: 20%;
     }

    @media screen and (min-width: 768px) {
        display: flex;
        justify-content: flex-start;
    }


    .bgrightloginform {
        width: 100%;
        background: #F4F5F7 0% 0% no-repeat padding-box;

        .registerYourAccount {

            width: 80%;
            margin: auto;
            margin-top: 15px;
            padding-top: 25px;
            min-height: 90vh;

            @media screen and (min-width: 1024px) {
                width: 65%;
             }


            .icondiv {
                color: #272B41;
                font-size: 14px;
                font-weight: 600;

                .facebook {
                    width: 44px;
                    height: 44px;
                    padding: 12px 17px;
                    color: white;
                    background: #3B5998 0% 0% no-repeat padding-box;
                    border-radius: 4px;
                }

                .justDial {
                    width: 44px;
                    height: 44px;
                    padding: 5px 8px;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 1px solid #FA8B0C;
                    border-radius: 4px;
                }


                .google {
                    width: 44px;
                    height: 44px;
                    padding: 12px 17px;
                    background-color: #2B8DFF;
                    color: #FFFFFF;
                    border-radius: 5px;
                }

                .tripadvisor {
                    width: 44px;
                    height: 44px;
                    padding: 5px 10px;
                    color: #000000;
                    background-color: #34e0a1;
                    border-radius: 5px;
                }

                .iconinput {
                    border: 1px solid #E3E6EF;
                    border-radius: 4px;
                    width: 40%;
                }

                ::placeholder {
                    color: #9299B8;
                    font-size: 13px;
                    font-weight: 400;
                }
            }

            .errormessage {
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 10px;
            }


            .buttons {
                gap: 18px;
                margin-top: 35px;

                .back {
                    border: 1px solid #E3E6EF;
                    border-radius: 5px;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    color: #9299B8;
                    font-weight: 600;
                    font-size: 14px;
                    height: 49px;
                    width: 86px;
                }

                .dolater {
                    width: 207px;
                    height: 49px;
                    background: #F7B217 0% 0% no-repeat padding-box;
                    border-radius: 8px;
                    color: #FFFFFF;
                    font-size: 14px;
                    font-weight: 600;
                }

                @media only screen and (max-width: 767px) {
                    margin-top: 30px;
                }
            }

        }

    }

}

;

.TaxiCRMImageDiv {
    display: none;
    width: 40%;
    height: 100vh;
    background: url(../../../assets/images/Left-BackGround.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0px 70px 70px 0px;
    padding: 20px 10px 0px 30px;

    @media only screen and(min-width:1000px){
        background-size: contain;
    }

    @media only screen and (min-width:768px){
        display: block;
    };

    .IconAndText {
        margin-top: 20vh;

        @media only screen and (min-width:1000px) {
            margin-top: 10vh;
            margin-left: 5%;
        }

        .imageText {
            @media only screen and (min-width:768px) and (max-width:800px) {
                font-size: 28px;
            }
            color: #272B41;
            font-size: 30px;
            font-weight: 600;
            margin-top: 30px;
        }
    };

    .TaxiLeftImage {
        width: 60%;
        margin: auto;
        margin-top: 15vh;

        @media only screen and (min-width:768px) and (max-width:999px) {
            width: 65%;
            margin: auto;
            margin-top: 10vh;
        }
    }

};

.addreview{
    font-size: 18px;
    font-weight: 600;
    color: #272B41;
}