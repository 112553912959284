* {
  font-family: 'Inter', sans-serif;
  letter-spacing: 0px;
}

.mainModal {
  border-radius: 10px;
}

.modalDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.4rem 1rem 0.5rem 1rem;

  .modalTitle {
    font-size: 1.1rem;
    font-weight: bold;
  }

  .modalCross {
    // border: 1px solid gray;
    border-radius: 100%;
    padding: 0 0.7rem 0.3rem;
    background-color: #f4f5f7;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    cursor: pointer;
  }
}

.selectDiv {
  .select {
    width: 100%;
    border-radius: 5px;
    height: 38px;
    border: 1px solid #e3e6ef;
    margin-top: 5px;

    .vehicleSelect {
      color: #9299B8;
      font-size: 14px;
    }
  }
}

.gridModal {

  // display: grid;
  //   grid-template-columns: repeat(1, 1fr);
  //   grid-template-rows: 100px;
  //   grid-auto-rows: 100px;
  //   grid-gap: 20px;
  .labelFont {
    font-size: 0.9rem;
  }

  .inputFont {
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 7px 5px 7px 5px;
  }
}

.AddVehicleButton {
  margin-left: 100px;
}

.inputlabelText {
  color: #272B41;
  font-size: 14px;
}

.labelUploadFont {
  font-size: 15px;
  color: #272B41;
  font-weight: 500;
  margin-bottom: 0px;
}


.labelFont {
  font-size: 15px;
}

.uploadRC {
  width: 48%;
}

.uploadVImg {
  width: 30%;
}

.profileIcons {
  color: #f8f9fb;
  width: 4rem;
  height: 4rem;
  border: 1px dashed #adb4d2;
  border-radius: 0.5rem;
  padding: 1rem;
}

.upload {
  border: 1.5px dashed;
  border-color: #c6d0dc;
  border-radius: 10px;
  border-width: 2.5px;
  margin-top: 10px;
  width: 100%;
}

.uploadbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 92px;
  gap: 6px;
}

.uploadboxVehicleImages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 92px;
}

.uploadText {
  text-align: center;
  color: #c6d0dc;
  font-size: 14px;
}

.uploadIcon {
  height: 32px;
  width: 69px;
  color: #c6d0dc;
}

.uploadSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;
  gap: 6px;
}

.uploadIconSmall {
  height: 100%;
  width: 100%;
  color: #c6d0dc;
}

.uploadIconSymbol {
  height: 34px;
  width: 32px;
  color: #c6d0dc;
}

.icons {
  color: #c6d0dc;
  width: 100px;
  height: 92px;
  border: 1.5px dashed #c4c9df;
  background-color: #c6d0dc;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
}

.addBtn {
  color: white;
}

.closeBtn {
  background-color: white;
  border: #eef0f5 solid 1px;
  color: gray;
  padding: 0 1rem;
}

.input {
  height: 48px;
  border: 1px solid #e3e6ef;
  border-radius: 5px;
  opacity: 1;
  padding: 0 0 0 1rem;
  font-size: 14px;
}


.fiRCUploadImage {
  height: 100%;
  width: 100%;
}

.profileIconImage {
  color: #f8f9fb;
  width: 100px;
  height: 100px;
  border: 1px dashed #adb4d2;
  border-radius: 0.5rem;
}

.driverProfileIcon {
  color: #9299b8;
  width: 100px;
  height: 100px;
  border: 1.5px dashed #c4c9df;
  background-color: #f8f9fb;
  border-radius: 0.5rem;
  border-width: 3.5px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.uploadImage {
  width: 209px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #c4c9df;
  border-radius: 0.2rem;
  padding: 0.4rem 1.4rem;
  color: #9299b8;
  opacity: 1;
}

.uploadLisence {
  width: 48%;
}

.buttonDiv {
  margin-top: 20px;
}

.addAssignButton {
  width: 129px;
  height: 45px;
  background: #F7B217 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 20px #5F63F21A;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.cancelButton {
  width: 86px;
  height: 44px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #E3E6EF;
  border-radius: 5px;
  color: #9299B8;
  margin-left: 10px;
}