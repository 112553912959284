* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

$badgeColor: #20c997;
$iconColor: #adb4d2;
$lightGray: #9299b8;

.mainContainer {
  padding: 0px 5px 0px 10px;
  width: 100%;
  @media screen and (min-width: 1024px) {
    margin-left: 20%;
  }
  .pageTitle {
    display: flex;
    justify-content: left;
    margin-top: 15px;
  }
  .addDriverBtnDiv {
    @media (min-width: 280px) and (max-width: 1023px) {
      display: none;
    }
    .addDriverBtn {
      color: white;
      padding: 5px 25px;
    }
  }
  .contentDiv {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    margin: 6px 1px;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    padding: 12px 12px;
    height: 100%;

    .nameTitle {
      font-size: 16px;
      color: #272b41;
      font-weight: 500;
    }

    .badge {
      color: $badgeColor;
      border-radius: 15px;
      background-color: #dcf6ee;
      font-size: 11px;
      font-weight: 600;
      padding: 1px 6px;
      text-transform: uppercase;
    }
    .deactiveBadge {
      color: red;
      border-radius: 15px;
      background-color: lightpink;
      text-transform: uppercase;
    }

    .iconStyle {
      color: $iconColor;
      cursor: pointer;
    }

    .detailsSection {
      width: 90%;
      @media (min-width: 280px) and (max-width: 1023px) {
        flex-direction: column;
      }
      .otherContentTitle {
        padding: 0px;
        margin: 0px;
        font-size: 12px;
        color: $lightGray;
      }
      .otherContentValue {
        font-size: 14px;
        font-weight: 500;
        color: #272b41;
      }
    }
  }
}

// ---------modal--------
.modalDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 1rem 0rem;

  .modalTitle {
    font-size: 1.1rem;
    font-weight: bold;
  }

  .modalCross {
    // border: 1px solid gray;
    border-radius: 100%;
    padding: 0 0.7rem 0.3rem;
    background-color: #f4f5f7;
    margin-bottom: 0.8rem;
    cursor: pointer;
  }
}
.icons {
  color: #9299b8;
  width: 100px;
  height: 100px;
  border: 1.5px dashed #c4c9df;
  background-color: #f8f9fb;
  border-width: 3.5px;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
}

.profileIcons {
  color: #c4c9df;
  color: #f8f9fb;
  width: 100px;
  height: 100px;
  border: 1px dashed #adb4d2;
  border-radius: 0.5rem;
}
::-webkit-input-placeholder {
  color: #adb4d2;
}

.profileImage {
  display: flex;
  align-items: center;
  gap: 20px;
}

.uploadFile {
  width: 50%;
  .uploadImage {
    height: 45px;
    display: block;
    max-width: 220px;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-items: center;
    border: 1px solid #c4c9df;
    border-radius: 0.2rem;
    padding: 0.75rem 1.4rem;
    color: #9299b8;
    opacity: 1;
    text-align: center;
  }
}

// input tags:-
.labelFont {
  font-size: 14px;
  color: #272b41;
  font-weight: 600;
}
.input {
  height: 48px;
  border: 1px solid #e3e6ef;
  border-radius: 5px;
  padding: 0 0 0 1rem;
}

// .addBtn {
//   color: white;
// }

.closeBtn {
  background: #ffffff;
  padding: 0 1rem;
  color: #9299b8;
  border: 1px solid #e3e6ef;
  border-radius: 5px;
}
$grayColor: #e3e6ef;
$activeTab: #f7b217;
$darkBlack: #272b41;

@media (min-width: 280px) and (max-width: 1023px) {
  .addBookingDiv {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background-color: $activeTab;
    position: fixed;
    bottom: 25px;
    right: 25px;
  }
}

.addBookingIcon {
  font-size: 20px;
  color: $grayColor;
  @media only screen and (min-width: 1024px) {
    display: none;
  }
}

.crossSign {
  position: relative;
}
