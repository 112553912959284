
.spinnerContainer{
    height: 84vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .spinnerStyling{
        width: 60px;
        height: 60px;
        margin-bottom: 100px;
    }
}