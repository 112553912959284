.myBooking {
    width: 100%;
    height: 111vh;
    background-color: #f4f5f7;
    @media only screen and (min-width:1024px) {
      margin-left: 20%;
    }
    .myBookingContainer {
      width: 100%;

      .myBookingFilter {
        width: 96%;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: auto;

        .type {
          margin: 0;
          color: #272b41;
          font-size: 20px;
          font-weight: 500;
        }

        
      }
    }

    .container {
      width: 96%;
      height: auto;
      margin: auto;
      background-color: #fff;
      border-radius: 8px;
      overflow-y: scroll;
      
     
    }
    .container::-webkit-scrollbar {
        display: none;
    }
  }