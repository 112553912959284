* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

// colors:-
$grayColor: #e3e6ef;
$activeTab: #f7b217;
$darkBlack: #272b41;

.mainContainer {
  @media (min-width: 1024px) and (max-width: 4000px) {
    display: none;
  }
}

.filtersMainContainer {
  background-color: #fbfbfb;

  .filtersDivTop {
    width: 100%;
    margin: 4.5%;

    @media (min-width: 1024px) and (max-width: 4000px) {
      display: none;
    }

    .cancelledBookingDiv {
      margin-bottom: 10px;
      >p {
        margin: 0;
        color: #272b41;
        font-weight: 500;
        font-size: 20px;
        font-weight: 500;
      }
     >div{
      gap: 10px;
      
      >button {
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 12px;
        padding: 8px 16px;
        border-radius: 3px;
        border: 1px solid #f1f2f6;
      }
      >button:first-child{
        background: #eff7ff;
      }
      >button:nth-child(2){
        background: #fff6f6;
      }

      .cancelledNavLink {
        color: #ff4d4f;
        font-size: 12px;
      }
      .completedNavLink {
        color: #007ae9;
        font-size: 12px;
      }
     }
     
    }

    .search {
      height: 34px;
      display: flex;
      align-items: center;
      color: #ffffff;
      border-radius: 3px;
      background-color: #ffffff;
      box-shadow: 0px 3px 5px #9299b80d;
      filter: drop-shadow(0 3px 5px #9299b80d);

      .searchIcon {
        margin: 0 5px 0 10px;
        color: #f7b217;
        font-size: 16px;
        cursor: pointer;
        opacity: 1;
      }

      .searchById {
        width: 100%;
        height: 15px;
        margin: 0;
        outline: none;
        border: none;
        font-size: 12px;
        opacity: 0.5;
        color: #5a5f7d;
        font-weight: 1000;
      }
    }
  }

  .vehicleNDate {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin: 10px 0;

    .selectVehicle,
    .selectDate {
      height: 34px;
      width: 100%;
      padding: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      opacity: 1;
      border-radius: 3px;
      box-shadow: 0px 3px 5px #9299b80d;
      filter: drop-shadow(0 3px 5px #9299b80d);

      .vehicleIcon,
      .eventIcon {
        font-size: 15px;
        color: #f7b217;
      }

      .vehicles,
      .month {
        border: none;
        padding: 0 6px;
        outline: none;
        font-size: 12px;
        font-weight: 1000;
        cursor: pointer;
        color: #5a5f7d;
        width: 100%;
      }
    }
  }
}

.box {
  width: 95%;
  overflow: auto;
  margin-left: 4.5%;
  border: 1px solid $grayColor;
  border-radius: 5px;
}

.box::-webkit-scrollbar {
  display: none;
}

.tabs {
  display: flex;
  justify-content: space-between;
  overflow: auto;
  width: 510px;
}

.tab {
  background-color: white;
  padding: 8px 10px;
  text-align: center;
  border-top: none;
  cursor: pointer;
  position: relative;
  font-size: 12px;
}

.activeTab {
  border: none;
  background-color: $activeTab;
  border-radius: 5px;
  color: white;
}

.contents {
  position: relative;
  margin-left: 2%;
}

.content {
  display: none;
  position: relative;
}

.addBookingDiv {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-color: $activeTab;
  position: fixed;
  bottom: 25px;
  right: 25px;
  .addBookingIcon {
    font-size: 20px;
    color: $grayColor;
  }
}

.activeContent {
  display: block;
  margin: 0px 10px;
}

.activeBookings {
  margin: 4.5%;
  font-size: 15px;
  color: $darkBlack;
  font-weight: bold;
}

.popupcontent {
  width: 350px !important;
  height: 300px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 70px 10px rgb(0, 0, 0/11%);
  border-radius: 10px;
  text-align: center;

  .imgbox {
    text-align: center;

    .CheckedIcon {
      width: 96px;
      height: 96px;
      margin-top: 20px;
    }
  }

  .sucessmessage {
    margin-top: 30px;
    color: #5A5F7D;
    font-size: 14px;
    font-weight: 400;
  }

  .dashboard {
    margin-top: 5px;
    font-size: 14px;
    color: #5A5F7D;
    font-weight: 400;
  }

  .button {
    margin-top: 15px;
    background-color: #F7B217;
    border-radius: 8px;
    width: 213px;
    height: 49px;
    font-size: 14px;
    color: #FFFFFF;
  }
}
