.deleteModal{
    border-radius: 10px;
    .deleteModalHeader{
        border: none!important;
        padding: 1rem 1rem 1px!important;
    };
};

.paymentDeatilsButton{
    margin:15px 0px;
    margin-top: 10px;
    height: 50px;
    padding: 5px;
    background-color: #F7B217 !important;
    color: white !important;
}