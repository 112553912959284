*{
    font-size: 14px;
}
.profileImage{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.profileImageDiv{
    width: 50px;
    height: 50px;
    margin-right: 15px;
}

.TimeStamp{
    color: #adb4d2;
    text-align: left;
}
.orangeText{
    color: #f7b217;
}
.border1{
    border: none;
    text-align: left;
}
