* {
    font-family: "Inter", sans-serif;
  }
  .mainContainer {
    background-color: rgba(244, 245, 247, 1);
    $darkColor: #272b41;
    $GrayColor: #5a5f7d;
    $blueBadge: #2c99ff;
    width: 100%;
    padding: 0px 20px;
    @media screen and (min-width: 1024px) {
      margin-left: 20%;
      width: 80%;
   }
  
    .backBtn {
      font-size: 14px;
      color: $darkColor;
      font-weight: 500;
      padding-top: 13px;
      @media (min-width: 280px) and (max-width: 769px) {
        margin: 5px 10px;
      }
    }
  
    .cancelledHeading {
      font-size: 20px;
      color: $darkColor;
      padding-bottom: 10px;
      @media (min-width: 280px) and (max-width: 769px) {
        padding: 0 3px;
        margin: 10px 5px 0px;
      }
    }
  
    .tableDiv {
      background-color: white;
      border-radius: 10px;
      padding: 20px;
      @media (min-width: 280px) and (max-width: 769px) {
        display: none;
      }
      
      .tableContainer{
        overflow-x: auto;
      
      &::-webkit-scrollbar{
       height: 7px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1; 
      }
      &::-webkit-scrollbar-thumb {
        background: rgb(151, 150, 150); 
        border-radius: 20px;
      }
    }
    }
    .tableHead {
      background-color: rgba(248, 249, 251, 1);
      white-space: nowrap;
      margin-left: 10px;
      .tableRow {
        color: $GrayColor;
        font-size: 14px;
        letter-spacing: 0px;
      }
    }
  
    .tableData {
      font-size: 14px;
      height: 45px;
      color: $darkColor;
      .statusOneWay {
        background-color: $blueBadge;
        width: 100%;
      }
      .statusRoundTrip {
        background-color: $darkColor;
        width: 100%;
      }
      .noneAssigned {
        display: none;
      }

      .assignedTo {
        height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: max-content;

        > img {
          width: 20px;
          height: 20px;
          border-radius: 20px;
        }

        > div {
          margin-left: 10px;

          > p:first-child {
            margin: 0;
            color: #272b41;
            font-size: 14px;
            font-weight: 500;
          }

          > p:last-child {
            margin: 0;
            color: #6e707f;
            font-size: 12px;
            font-weight: 400;
          }
        }
      }
    }
    .boxIcon {
      height: 14px;
      width: 14px;
      border-radius: 4px;
      border: rgba(198, 208, 220, 1) solid 1px;
      background-color: white;
      margin-bottom: 4px;
    }
    .paginationDiv {
      @media (min-width: 280px) and (max-width: 769px) {
        display: none;
      }
    }
  }
  