* {
  font-family: "Inter", sans-serif;
  letter-spacing: 0px;
}

.modalDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 1rem 0rem;

  .modalTitle {
    font-size: 1.1rem;
    font-weight: bold;
  }

  .modalCross {
    // border: 1px solid gray;
    border-radius: 100%;
    padding: 0 0.7rem 0.3rem;
    background-color: #f4f5f7;
    margin-bottom: 0.8rem;
    cursor: pointer;
  }
}

.gridModal {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 70px;
  grid-auto-rows: 100px;
  grid-gap: 20px;
  @media (min-width: 280px) and (max-width: 320px) {
    grid-template-columns: repeat(1, 1fr);
  }
  .labelFont {
    font-size: 0.9rem;
  }
  .inputFont {
    border: 1px solid #dddddd;
    border-radius: 5px;
    width: 100%;
    padding: 7px 5px 7px 5px;
  }
}

.buttonDesktopDiv {
  @media only screen and (max-width: 1023px) {
    display: none;
  }
  display: flex;
}

.buttonMobileDiv {
  @media only screen and (min-width: 1024px) {
    display: none;
  }
  display: flex;
};

.pickUpTime > div div{
   height: 34px;
};

.pickUpTime > div div input:focus{
  box-shadow: none !important;
};