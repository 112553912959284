html {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.paginationDiv {
  display: flex;
  justify-content: end;
  align-items: center;
  margin: 5px 0px;
  // position: fixed;
  // bottom: 20px;
  // right: 20px;
}

// pagination:-

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  gap: 5px;
  margin-top: 1rem;
}

.pagination .pageNum {
  padding: 5px 12px;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid white;
  background-color: white;
  border: 1px solid #f1f2f6;
}

.pagination .pageNum:hover {
  background-color: #ffc107;
  color: white;
}

.pagination .activeColor {
  background-color: #ffc107;
  border: none;
  color: white;
}

.pagination .disableButtons {
  cursor: not-allowed;
  background-color: rgb(243, 236, 236);
}
.pagination .disableButtons:hover {
  background-color: rgb(243, 236, 236);
}

.selectDiv {
  height: 30px;
  margin: 0 10px;
}

.selectTag {
  width: 100%;
  height: 100%;
  border: none;
  padding: 0px;
  border-radius: 5px;
}
