* {
  font-family: "Inter", sans-serif;
  font-size: small;
}

.popUpScroller {
  background-color: white;

  .bellicon {
    margin-top: 7px;
    width: 18px;
    height: 18px;
  }
}

.buttonArea {
  text-align: center;
  padding-top: 4px;

  .nothingToShow{
     padding: 10px;
     color: #9299B8;
     font-size: 14px;
  }

  .viewNotificationbutton {
    margin-top: 10px;
    margin-bottom: 5px;
    padding: 12px;
    color: #9299B8;
    border: 1px solid #ADB4D2;
    font-weight: 500;
    font-size: 14px;
    background-color: #FFFFFF;
    border-radius: 5px;
  }
};

.popOverStyle{
  padding: 4px;
  cursor: pointer;
  min-width: 390px;
  border-radius: 5px;
  background-color:white;
  border: 1px solid #d7d9e2;
  @media (min-width:250px) and (max-width:320px) {
    min-width: auto;
};
  @media (min-width:319px) and (max-width:350px) {
      min-width: 295px;
  };

  @media (min-width:351px) and (max-width:400px) {
      min-width: 340px;
  };
  
};

.ListGroup{
  overflow-y: auto;
  max-height: 330px;
};

.bellIconNotification{
   display: flex;
   flex-direction: column;
}

.bellIconDiv{
  position: fixed;
   margin-top: 3px;
   top: 0px;
}