* {
  font-family: Inter;
  letter-spacing: 0px;
}

.logincomponent {
  @media screen and (min-width: 768px) {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 0px -0px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    align-items: center;
    margin: 0px 0px;
  }

  .bgleftimage {
    // height: 100vh;
    // width: 40%;
    // background-size: cover;
    // background-repeat: no-repeat;
    // border-radius: 0px 70px 70px 0px;

    @media screen and (min-width: 1000px) {
      height: 100vh;
      width: 35%;
      background-size: contain;
      background-repeat: no-repeat;
      border-radius: 0px 70px 70px 0px;
    }
  }

  .bgleftimageMediumDiv {
    display: none;

    @media screen and (min-width: 768px) and (max-width: 1000px) {
      width: 70%;
      display: block;
    }

    .bgleftimageMedium {
      display: none;

      @media screen and (min-width: 768px) and (max-width: 1000px) {
        display: block;
        height: 100vh;
        width: 100%;
      }
    }
  }

  .taxiIcon {
    display: none;

    @media only screen and (max-width: 767px) {
      display: block;
      width: 165px;
      height: 37px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 14px;
      margin-bottom: 15px;
    }
  }

  .bgrightloginform {
    margin-top: 20px;
    width: 100%;

    @media only screen and (min-width: 1023px) {
      width: 65%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1023px) {
      width: 65%;
    }

    .donotaccount {
      text-align: end;
      margin-right: 20px;
      color: #909090;

      .registernow {
        margin-left: 7px;
        border-bottom: 2px solid black;
        color: black;
      }
    }

    .registerYourAccount {
      margin-top: 10%;
      padding: 0px 50px;
      width: 100%;

      @media only screen and (max-width: 767px) {
        margin-top: 40px;
        padding: 0px 10px;
      }

      @media only screen and (min-width: 768px) and (max-width: 1023px) {
        margin-top: 20%;
        padding: 0px 50px;
      }

      .registerText {
        font-size: 24px;
        font-weight: bolder;
      }

      .addVehicleFont {
        font-size: 14px;
        font-weight: 300;
        color: #909090;
      }

      .stepText {
        margin-top: -1px;
        margin-right: 10px;
        font-size: 13px;
        color: #272b41;
        font-weight: 500;
      }

      .addVechile {
        margin-top: 30px;
        font-weight: 500;
        height: 45px;
        width: 153px;
        border-radius: 10px;
        padding: 10px 10px 10px 10px;
        margin-left: 0px;
        margin-bottom: 20px;
        background: #f8f9fb 0% 0% no-repeat padding-box;
        border: 1px solid #f1f2f6;

        @media only screen and (max-width: 767px) {
          min-width: 276px;
          width: 90%;
          margin-top: 30px;
        }
      }
    }
  }
}

.uploadRC1 {
  height: 10%;
  width: 450px;
}

.fiUpload1 {
  height: 42px;
  width: 450px;
  color: gray;
}

.fiUpload2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 92px;
  width: 97px;
  border-radius: 10px;
  border: 2px dashed #c6d0dc;

  .fileUploadSymbol {
    height: 22px;
    width: 22px;
    color: #adb4d2;
  }

  .fileUploadText {
    font-size: 14px;
    color: #9299b8;
    font-weight: 400;
  }
}

.fiRCUpload {
  height: 110px;
  width: 220px;
  color: gray;
  border: 2px dashed rgb(112, 112, 112);
}

.modalBody {
  height: 50%;
  width: 100%;
}

option {
  height: 50px;
  overflow-y: scroll;
}

.modalMainDiv {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  padding-top: 10px;
  width: 391px;
  max-height: max-content;
  background-color: #ffffff;
  border-radius: 10px;

  @media only screen and (max-width: 767px) {
    width: 318px;
  }

  .modalDiv {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    border-radius: 10px;

    .modalTitle {
      color: #272b41;
      font-size: 16px;
      font-weight: 600;
      margin-left: 5%;
    }

    .closeButton {
      color: #9299b8;
      font-size: 20px;
      font-weight: 600;
      cursor: pointer;
      margin-right: 5%;
    }
  }

  .mainBodyDiv {
    .selectVechileTag {
      width: 100%;
      height: 48px;
      border-radius: 5px;
      border: 1px solid #e3e6ef;
      background-color: #ffffff;

      ::placeholder {
        color: #9299b8;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .driverName {
      width: 100%;
      height: 48px;
      border-radius: 5px;
      border: 1px solid #e3e6ef;
      background-color: #ffffff;

      ::placeholder {
        color: #9299b8;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .driverContactNumber {
      width: 100%;
      height: 48px;
      border-radius: 5px;
      border: 1px solid #e3e6ef;
      background-color: #ffffff;

      ::placeholder {
        color: #9299b8;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .labelFont {
      width: 100%;

      .uploadRCText {
        font-size: 15px;
        margin-bottom: 0px;
        font-weight: 500;
        color: #272b41;
      }

      .uploadRCDiv {
        display: flex;
        width: 100%;

        .rCImageDiv {
          height: 110px;
          width: 210px;
          display: flex;
          border: 2px dashed #c6d0dc;
          border-radius: 10px;
          width: 160px;
          height: 92px;

          @media only screen and (max-width: 767px) {
            width: 130px;
          }

          .fileUploadDiv {
            display: flex;
            gap: 10px;
            margin: auto;

            .RCfontFront {
              font-size: 14px;
              font-weight: 400;
              color: #9299b8;
            }

            .fiRCUpload {
              height: 22px;
              width: 22px;
              color: #adb4d2;
              border: none;
            }
          }

          .RCfontBack {
            position: absolute;
            top: 51%;
            left: 70.5%;
            transform: translate(-50%, 50%);
            color: #909090;
          }

          .fiRCUploadImage {
            height: 100%;
            width: 100%;
          }
        }
      }
    }

    .actualImagesDiv {
      .actualImagesTitle {
        font-size: 15px;
        margin-bottom: 0px;
        font-weight: 500;
        color: #272b41;
      }

      .actualImagesInnerDiv {
        margin-top: 0px;
        display: flex;
        gap: 10px;
      }
    }
  }

  .modalButton {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-top: -5px;
    margin-right: 5px;

    .modalCancelButton {
      width: 80px;
      height: 39px;
      border: 1px solid #e3e6ef;
      background-color: #ffffff;
      color: #272b41;
      border-radius: 5px;
      font-size: 14px;
    }

    .modalAddButton {
      width: 80px;
      height: 39px;
      border: none;
      background-color: #f7b217;
      border-radius: 5px;
      font-size: 14px;
      color: #ffffff;
    }
  }
}

.uploadRCInnerDiv {
  width: 50%;
}

.vehicleUpload {
  display: flex;
}

.fiUpload2 {
  height: 92px;
  width: 97px;
  border-radius: 10px;
  border: 2px dashed #c6d0dc;

  @media only screen and (max-width: 767px) {
    height: 92px;
    width: 78px;
  }
}

.buttonDiv {
  gap: 10px;
  position: absolute;
  bottom: 10px;

  @media only screen and (max-width:767px){
     padding-left: 10px;
  }

  @media only screen and (min-width: 768px) {
    position: relative;
    margin-top: 40px;
  }

  .submitButton {
    width: 185px;
    height: 49px;
    padding: 8px 8px 8px 8px;
    background-color: #f7b217;
    border-radius: 8px;
    border: none;
    color: white;
  }
}

.TaxiCRMImageDiv {
  display: none;
  width: 40%;
  height: 100vh;
  background: url(../../../assets/images/Left-BackGround.png);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0px 70px 70px 0px;
  padding: 20px 10px 0px 30px;

  @media only screen and(min-width:1000px) {
    background-size: cover;
    width: 35%;
  }

  @media only screen and (min-width: 768px) {
    display: block;
  }

  .IconAndText {
    margin-top: 20vh;

    @media only screen and (min-width: 1000px) {
      margin-top: 10vh;
      margin-left: 5%;
    }

    .imageText {
      @media only screen and (min-width: 768px) and (max-width: 800px) {
        font-size: 28px;
      }
      color: #272b41;
      font-size: 30px;
      font-weight: 600;
      margin-top: 30px;
    }
  }

  .TaxiLeftImage {
    width: 60%;
    margin: auto;
    margin-top: 15vh;

    @media only screen and (min-width: 768px) and (max-width: 999px) {
      width: 65%;
      margin: auto;
      margin-top: 10vh;
    }
  }
}

.profileIconImage {
  color: #f8f9fb;
  width: 100px;
  height: 100px;
  border: 1px dashed #595e75;
  border-radius: 0.5rem;
}

.uploadImage {
  width: 100%;
  height: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  max-width:220px;
  justify-content: center;
  align-items: center;
  border: 1px solid #c4c9df;
  border-radius: 0.2rem;
  padding: 0.75rem 0.8rem;
  margin: auto;
  color: #9299b8;
  opacity: 1;
  text-align: center;

  // @media only screen and (min-width: 768px) {
  //   padding: 0.4rem 1.4rem;
  // }
}

.icons {
  color: #9299b8;
  width: 100px;
  height: 100px;
  border: 1.5px dashed #c4c9df;
  background-color: #f8f9fb;
  border-radius: 0.5rem;
  border-width: 3.5px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.uploadImageInput {
  background-color: red;
};

.errormessage {
   font-size: 14px;
   margin-top: 0px;
}

.crossSign {
  position: relative;
  cursor: pointer;
}
.imageBox{
  width: 100%;
  height: 100%;
}