* {
  font-family: "Inter", sans-serif;
  letter-spacing: 0px;
}

.sidebar {
  margin-top: 0;
  width: 18%;
  opacity: 1;
  height: 100%;
  min-height: 90vh;
  padding: 15px 0px;
  top: 60px;
  left: 0;
  position: fixed;

  // box-shadow: 0px 0px 30px #9299b81a;
  // filter: drop-shadow(0 0 30px #9299b81a);
  background: #ffffff 0% 0% no-repeat padding-box;
  @media (min-width: 280px) and (max-width: 1023px) {
    display: none;
  }

  .sidebarElements {
    width: 90%;
    height: 32px;
    display: flex;
    padding: 5px 10px;
    margin: 10px;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    background-color: #ffffff;

    .sidebarIcon {
      font-size: 20px;
      color: #adb4d2;
    }

    > p {
      margin: 0;
      color: #46474c;
      padding-left: 8px;
      font-size: 14px;
      font-weight: 400;
      font-family: "Inter", sans-serif;
    }
  }

  .sidebarElements:hover {
    background-color: #f7b217;
    .sidebarIcon,
    > p {
      color: #ffffff;
    }
  }
}

.activeLink {
  width: 90%;
  height: 32px;
  display: flex;
  padding: 5px 10px;
  margin: 10px;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f7b217;
  color: white;
  p {
    margin: 0;
    color: white;
    padding-left: 8px;
    font-size: 14px;
    font-weight: 400;
    font-family: "Inter", sans-serif;
  }
  .sidebarIcon {
    font-size: 20px;
    // color: #adb4d2;
  }
}

.activeLink:hover {
  color: white;
}
