* {
  font-family: "Inter", sans-serif;
}

$lightGray: #5a5f7d;
$lightBlue: #2c99ff;
$DarkBlack: #272b41;
$Gray: #9299b8;
$green: #20c997;
$blueBadge: #2c99ff;

.container {
  border-radius: 10px;
  padding: 8px;
  @media (min-width: 769px) and (max-width: 5000px) {
    display: none;
  }

  .contentCard {
    border-radius: 10px;
    background-color: white;
    padding: 1px 0;
    margin: 15px 0px;
  }

  .id {
    font-size: 15px;
    letter-spacing: 0px;
    color: $lightGray;
    margin: 0;
  }
  .statusOneWay {
    background-color: $blueBadge;
  }
  .statusRoundTrip {
    background-color: $DarkBlack;
  }
  .verticalDot {
    color: $lightGray;
    cursor: pointer;
  }
  .customerName {
    font-size: 16px;
    color: $DarkBlack;
    letter-spacing: 0px;
    opacity: 1;
    margin: 0px;
  }
  .mobileNumber {
    font-size: 14px;
    color: $lightGray;
    letter-spacing: 0px;
    opacity: 1;
  }
  .dateNTimeHeading {
    font-size: 12px;
    color: $Gray;
    margin-bottom: 0px;
  }
  .dateNTime {
    font-size: 14px;
    color: $DarkBlack;
    letter-spacing: 0px;
    opacity: 1;
  }
  .vehicleHeading {
    font-size: 12px;
    color: $Gray;
    margin-bottom: 0px;
  }
  .vehicle {
    font-size: 14px;
    color: $DarkBlack;
    letter-spacing: 0px;
    opacity: 1;
  }
  .locationHeading {
    font-size: 12px;
    color: $Gray;
    margin-bottom: 0px;
  }
  .location {
    font-size: 14px;
    color: $DarkBlack;
    letter-spacing: 0px;
    opacity: 1;
  }
  .amountHeading {
    font-size: 12px;
    color: $Gray;
    margin-bottom: 0px;
  }
  .amount {
    font-size: 14px;
    color: $green;
    letter-spacing: 0px;
    opacity: 1;
  }
  .driverDetail,.vehicleDetail{
    
    display: flex;
    flex-direction: column;
  
    >p{
      color:$Gray;
      margin-bottom: 4px;
      font-size: 12px;
    }
    >div{
      display: flex;
     
      > img {
        width: 30px;
        height: 30px;
        border-radius: 20px;
      }
  
      > div {
        margin-left: 10px;
  
        > p:first-child {
          margin: 0;
          color: #272b41;
          font-size: 14px;
          font-weight: 500;
        }
  
        > p:last-child {
          margin: 0;
          color: #272b41;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
    }
   
  .vehicleDetail{
    margin-bottom: 10px;
  }
}
