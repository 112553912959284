html, body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #272B41;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  .customButtonActive {
    background: #FBBB00;
    border-color: #FBBB00;
    color: #fff;
    border-radius: 4px;
    width: 150px;
    &:hover {
      background: #F7B217;
      border-color: #F7B217;
      color: #fff;
    }
    @media only screen and (max-width: 768px){
          width: 326px;
          height: 49px;
          box-shadow: 0px 3px 5px #20C9970D;

    }
    @media (min-width:280px) and (max-width: 320px){
      width:100%;
  }
  }
  
  a {
    color: #272B41;
    text-decoration: none;
    &:hover {
      color: #272B41;
      text-decoration: none;
    }
  }
  .form-check-input:checked {
    background: #FBBB00;
    border-color: #FBBB00;
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    background-color: #f7b217;
  };

  .offcanvas.offcanvas-start{
     top: 60px;
  };

  .offcanvas-backdrop{
     top: 60px;
  };

  .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret{
     color: #F7B217;
  };


.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgb(247, 178, 23);
  outline: none;
  border: none;
  border-radius: 5px;
} 
form{
  input:focus{
    box-shadow: 0 0 0 0.2rem rgb(247, 178, 23);
    outline: none;
    border: none;
    border-radius: 5px;
  }

  select{
    border-radius: 5px;
  }
  select:focus{
    box-shadow: 0 0 0 0.2rem rgb(247, 178, 23);
    outline: none;
    border: none;
    border-radius: 5px;
  }
}
// .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input:focus{
//   box-shadow: none !important;
// }

// .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
//   height: 34px !important;
// }

// .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
//   height: 34px !important;
// };

// .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:focus{
//   box-shadow: none !important;
// }
// input::-webkit-outer-spin-button, input::-webkit-inner-spin-button { display: none; }