.logincomponent {
  @media screen and (min-width: 768px) {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 0px 0px;

  }

  @media screen and (max-width: 767px) {
    height: 100%;
    width: 100%;
  }

  .taxiIcon {
    display: none;

    @media only screen and (max-width: 767px) {
      display: block;
      width: 165px;
      height: 37px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 14px;
      margin-bottom: 20px;
    }
  }

  .bgleftimage {
    // height: 100vh;
    // width: 40%;
    // background-size: cover;
    // background-repeat: no-repeat;
    // border-radius: 0px 70px 70px 0px;

    @media screen and (min-width: 1000px) {
      height: 100vh;
      width: 40%;
      background-size: contain;
      background-repeat: no-repeat;
      border-radius: 0px 70px 70px 0px;
    }
  }

  .bgleftimageMediumDiv {
    display: none;

    @media screen and (min-width: 768px) and (max-width: 1000px) {
      width: 70%;
      display: block;
    }

    .bgleftimageMedium {
      display: none;

      @media screen and (min-width: 768px) and (max-width: 1000px) {
        display: block;
        height: 100vh;
        width: 100%;
      }
    }

    ;
  }

  .bgrightloginform {
    width: 100%;

    @media only screen and (min-width: 768px) {
      width: 70%;
      height: 100vh;
      overflow-y: scroll;
      padding: 0px 20px 0px 20px;
    }

    .donotaccount {
      @media only screen and (min-width: 768px) {
        margin-top: 20px;
        text-align: end;
        margin-right: 20px;
        color: #909090;
        margin-right: 10px;
      }

      .alreadyaccount {
        color: #5A5F7D;
        font-size: 14px;
        font-weight: 600;
      }

      .signin {
        font-weight: 600;
        margin-left: 7px;
        color: #272B41;
        text-decoration: underline;
        font-size: 14px;
      }

      @media only screen and (max-width: 767px) {
        display: flex;
        justify-content: flex-end;
        margin-right: 10px;
      }

    }

    .registerYourAccount {
      margin: 8% auto 0 auto;
      padding: 0px 20px 0px 20px;
      @media only screen and (min-width:410px) and (max-width:550px){
        padding: 0px 30px 0px 30px;        
      }

      @media only screen and (max-width:409px){
        padding: 0px 20px 0px 20px;
      }


      @media only screen and (max-width: 550px) {
        margin: 8% auto 0 auto;
        max-width: 480px;
      }

      .registeraccount {
        font-size: 24px;
        color: #272B41;
        font-weight: 600;

        @media only screen and (min-width: 1000px) {
          margin-left: 0px;
        }
      };

      .pricingplandiv {
        .pricingplan {
          font-size: 13px;
          color: #5A5F7D;
          font-weight: 400;
          letter-spacing: 0px;
          min-width: 180px;
          margin-right: 15px;

          @media only screen and (min-width: 1000px) {
            margin-left: 0px;
          }
        }

        ;

        .step5 {
          margin-top: 0px;
          color: #272B41;
          font-size: 13px;
          font-weight: 600;
          margin-right: 40px;

          @media only screen and (max-width: 767px) {
            margin-right: 0px;
          }
        }
      }
    }

    .pricing {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin-top: 67px;
      @media only screen and (max-width:549px){
        margin-top: 40px;
      }

      .plan1 {
        background-color: #fff;
        width: 286px;
        border-radius: 10px;
        margin: 10px auto 24px auto;
        // padding-left: 30px;
        // transition: 0.3s;
        cursor: pointer;
        border: 2px solid #F1F2F6;
        box-shadow: 0px 5px 20px #9299B808;
        border-radius: 10px;
        text-align: center;
        
        @media only screen and (max-width:549px){
          width: 100%;
        }

        @media only screen and (min-width:550px) and (max-width:768px){
          width: 47%;
        }

        &:hover {
          // box-shadow: 0px 5px 20px #9299B808;
          // background: #e8f9f4;
        }

        @media only screen and (min-width: 1000px) {
          margin: 10px 10px 24px 10px;
          width: 242px;
        }

        .pricinglabel {
          height: 30px;
          border-radius: 15px;
          padding: .35rem 1rem;
          margin-bottom: 1rem;
          margin-top: 26px;
          display: inline-block;
          font-size: 13px;
          font-weight: 600;
          text-align: center;
          color: #3c4255;
          background-color: #d4e4e2;
        }

        .price {
          margin-bottom: 1rem;
          font-size: 24px;
          font-weight: 600;
          color: #272B41;
          margin-top: 0px;

        }

        ul.features {
          list-style-type: none;
          padding: 0px 10px;
          text-align: left;
          color: #5A5F7D;
          left: 0px;

          li {
            margin: 8px 0px;
            color: #5A5F7D;
            font-size: 14px;
            font-weight: 400;

            span {
              color: #6ab04c;
            }
          }
        }

        .activeplan {
          margin-bottom: 30px;
          margin-top: 15px;
          border: 1px solid #E3E6EF;
          width: 144px;
          height: 44px;
          background: #20C997 0% 0% no-repeat padding-box;
          color: #FFFFFF;
          border-radius: 6px;
          cursor: pointer;
          font-size: 14px;
          font-weight: 600;
        }

        .getstartedIst {
          margin-bottom: 30px;
          margin-top: 15px;
          border: 1px solid #20C997;
          width: 138;
          height: 44px;
          padding: 12px 35px;
          border-radius: 6px;
          color: #20C997;
          background: #FFFFFF 0% 0% no-repeat padding-box;
          cursor: pointer;
          font-size: 14px;
          font-weight: 600;
        }

        &.popular {
          border: 2px solid #6ab04c;
          position: relative;
          transform: scale(1.08);

          span {
            position: absolute;
            top: -20px;
            left: 50%;
            transform: translateX(-50%);
            background-color: #6ab04c;
            color: #fff;
            padding: 4px 20px;
            font-size: 18px;
            border-radius: 5px;
          }
        }

        // &:hover {
        //   box-shadow: 5px 7px 67px -28px rgba(0, 0, 0, 0.37);
        // }

        .discountprice {
          .pricestyling {
            text-decoration: line-through;
            color: #9299B8;
            font-size: 16px;
            font-weight: 400;
          }

          .originalprice {
            font-size: 24px;
            font-weight: 600;
            color: #272B41
          }

          .rupeeIcon {
            color: #ADB4D2;
            font-size: 18px;
            margin-top: -15px;
            margin-right: -5px;
          }
        }

        ;
      }

      ;

      .plan2 {
        border: 1px solid #F1F2F6;
        width: 286px;
        height: 366px;
        border-radius: 10px;
        margin: 12px;
        border-radius: 5px;
        transition: 0.3s;
        cursor: pointer;
        box-shadow: 0px 5px 20px #9299B808;
        background: #FFFFFF 0% 0% no-repeat padding-box;

        @media only screen and (max-width: 767px) {
          width: 276px;
          min-width: 276px;
          height: 366px;
          margin: auto;
          margin-top: 30px;
          margin-bottom: 30px;
        }

        .title {
          display: flex;

          .pricinglabel {
            width: 121px;
            height: 32px;
            padding: .35rem .5rem;
            margin-bottom: 10px;
            margin-top: 26px;
            display: inline-block;
            font-size: 13px;
            font-weight: 600;
            margin-left: 25px;
            text-align: center;
            color: #5F63F2;
            background: #efeffe 0% 0% no-repeat padding-box;
            border-radius: 16px;
          }

          .discountoff {
            height: 21px;
            width: 43px;
            background: #FF4D4F 0% 0% no-repeat padding-box;
            margin-top: 30px;
            margin-left: 10px;
            color: #FFFFFF;
            font-size: 12px;
            font-weight: 600;
            border-radius: 3px;
            padding: 2px 7px;
          }
        }

        .discountprice {
          margin-left: 25px;

          .pricestyling {
            text-decoration: line-through;
            color: #9299B8;
            font-size: 16px;
            font-weight: 400;
          }

          .originalprice {
            font-size: 36px;
            font-weight: 700;
            margin-left: 5px;
            color: #272B41
          }
        }

        ul.features {
          list-style-type: none;
          padding: 0px 10px;
          text-align: left;
          color: #5A5F7D;
          left: 0px;

          li {
            margin: 8px 0px;
            color: #5A5F7D;
            font-size: 14px;
            font-weight: 400;

            span {
              color: #6ab04c;
            }
          }
        }

        .getstarted2nd {
          border: 1px solid #5F63F2;
          width: 138;
          height: 44px;
          padding: 12px 35px;
          border-radius: 6px;
          color: #5f63f2;
          background: #FFFFFF 0% 0% no-repeat padding-box;
          cursor: pointer;
          font-size: 14px;
          font-weight: 600;
          margin-left: 30px;
        }
      }

      .plan3 {
        border: 1px solid #F1F2F6;
        width: 286px;
        height: 366px;
        border-radius: 10px;
        margin: 12px;
        border-radius: 5px;
        transition: 0.3s;
        cursor: pointer;
        box-shadow: 0px 5px 20px #9299B808;
        background: #FFFFFF 0% 0% no-repeat padding-box;

        @media only screen and (max-width: 767px) {
          width: 276px;
          min-width: 276px;
          height: 366px;
          margin: auto;
        }

        .title {
          display: flex;

          .pricinglabel {
            width: 100px;
            height: 32px;
            padding: .35rem .5rem;
            margin-bottom: 10px;
            margin-top: 26px;
            display: inline-block;
            font-size: 13px;
            font-weight: 600;
            margin-left: 25px;
            text-align: center;
            color: #FF69A5;
            background: #fff0f6 0% 0% no-repeat padding-box;
            border-radius: 16px;
          }

          .discountoff {
            height: 21px;
            width: 43px;
            background: #FF4D4F 0% 0% no-repeat padding-box;
            margin-top: 30px;
            margin-left: 10px;
            color: #FFFFFF;
            font-size: 12px;
            font-weight: 600;
            border-radius: 3px;
            padding: 2px 7px;
          }
        }

        .discountprice {
          margin-left: 25px;

          .pricestyling {
            text-decoration: line-through;
            color: #9299B8;
            font-size: 16px;
            font-weight: 400;
          }

          .originalprice {
            font-size: 36px;
            font-weight: 700;
            margin-left: 5px;
            color: #272B41
          }
        }

        ul.features {
          list-style-type: none;
          padding: 0px 10px;
          text-align: left;
          color: #5A5F7D;
          left: 0px;

          li {
            margin: 8px 0px;
            color: #5A5F7D;
            font-size: 14px;
            font-weight: 400;

            span {
              color: #6ab04c;
            }
          }
        }

        .getstarted3rd {
          border: 2px solid #ff69a5;
          width: 138px;
          height: 44px;
          padding: 12px 15px;
          border: 1px solid #FF69A5;
          background: #FFFFFF 0% 0% no-repeat padding-box;
          border-radius: 6px;
          color: #FF69A5;
          margin-left: 30px;
          cursor: pointer;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }

    .buttons {
      gap: 18px;
      display: flex;
      justify-content: center;
      margin-top: 15px;
      margin-bottom: 15px;

      @media only screen and (min-width: 1000px) {
        justify-content: center;
      }

      .back {
        border: 1px solid #E3E6EF;
        border-radius: 5px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        color: #9299B8;
        font-weight: 600;
        font-size: 14px;
        height: 50px;
        width: 80px;
      }

      .submit {
        width: 147px;
        height: 49px;
        background: #F7B217 0% 0% no-repeat padding-box;
        border-radius: 8px;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

}

.popupcontent {
  width: 320px !important;
  height: 290px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 70px 10px rgb(0, 0, 0/11%);
  border-radius: 10px;
  text-align: center;
  border: none !important;

  .imgbox {
    text-align: center;

    .CheckedIcon {
      width: 75px;
      height: 75px;
    }
  }

  .sucessmessage {
    margin-top: 30px;
    color: #5A5F7D;
    font-size: 14px;
    font-weight: 400;
  }

  .dashboard {
    margin-top: 5px;
    font-size: 14px;
    color: #5A5F7D;
    font-weight: 400;
  }

  .button {
    border: none;
    margin-top: 15px;
    background-color: #F7B217;
    border-radius: 8px;
    width: 213px;
    height: 49px;
    font-size: 14px;
    color: #FFFFFF;
  }
}

.TaxiCRMImageDiv {
  display: none;
  width: 40%;
  height: 100vh;
  background: url(../../../assets/images/Left-BackGround.png);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0px 70px 70px 0px;
  padding: 20px 10px 0px 30px;

  @media only screen and(min-width:1000px){
      background-size: cover;
      width: 35%;
  }

  @media only screen and (min-width:768px){
      display: block;
  };

  .IconAndText {
      margin-top: 20vh;

      @media only screen and (min-width:1000px) {
          margin-top: 10vh;
          margin-left: 5%;
      }

      .imageText {
          @media only screen and (min-width:768px) and (max-width:800px) {
              font-size: 28px;
          }
          color: #272B41;
          font-size: 30px;
          font-weight: 600;
          margin-top: 30px;
      }
  };

  .TaxiLeftImage {
      width: 60%;
      margin: auto;
      margin-top: 15vh;

      @media only screen and (min-width:768px) and (max-width:999px) {
          width: 65%;
          margin: auto;
          margin-top: 10vh;
      }
  }

}