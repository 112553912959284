* {
    font-family: 'Inter', sans-serif;
    letter-spacing: 0px;
    font-size: 14px;
  }
  
  .modalDiv {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1rem 1rem 0rem;
    
      .modalTitle {
        // font-size: 1.1rem;
        font-weight: bold;
      }
    
      .modalCross {
        // border: 1px solid gray;
        border-radius: 100%;
        padding: 0 0.7rem 0.3rem;
        background-color: #f4f5f7;
        margin-bottom: 0.8rem;
        cursor: pointer;
      }
    }
  
  .gridModal{
    display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 100px;
      grid-auto-rows: 100px;
      grid-gap: 20px;
      .labelFont {
        font-size: 0.9rem;
      }
      .inputFont{
        border: 1px solid #dddddd;
        border-radius: 5px;
        padding: 7px 5px 7px 5px;
      }
  }
  
  .buttonDiv{
    .sendQr,.sendLink{
        background-color: #272B41;
        color: white;
        :hover{
            background-color: rgb(238, 255, 0);
            color: #272B41;
        }
    }
    
    .sendCash{
        background-color: #20C997;
        color: white;
    };

    .tripCompleted{
      background-color: #f7b217;
      color: white;
  }
    
  }

  