.PageNotFoundMainDiv{
    .taxiIconDiv{
        margin-top: 30px;
        height: 100px;
        display: flex;
        justify-content: center;

        .taxiIcon{
            height: 60px;
            width:  200px;
        }
    }
    .PageNotFoundDiv{
        display: flex;
        justify-content: center;
        .PageNotFound{
            height: 300px;
            width:  55%;
        }
    };
    .dashboardButtonDiv{
        display: flex;
        justify-content: center;
        margin-top: 30px;
        .dashboardButton{
            color: white;
            background-color: #1F51FF;
            border-radius: 5px;
            border: #1F51FF;
            padding: 15px;
            background-color: none;
        };
    }
}