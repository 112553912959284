* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.noDataFoundImage {
  height: 400px;
  width: 400px;
  @media (min-width: 0px) and (max-width: 1023px) {
    height: 100%;
    width: 100%;
  }
}
