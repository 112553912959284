* {
  font-family: "Inter", sans-serif;
}
.mainContainer {
  width: 100%;
  height: fit-content;
  padding: 0px 20px;
  background-color: rgba(244, 245, 247, 1);
  $darkColor: #272b41;
  $GrayColor: #5a5f7d;
  $blueBadge: #2c99ff;
  @media screen and (min-width: 1024px) {
    margin-left: 20%;
    width: 80%;
 }

  .backBtn {
    font-size: 14px;
    color: $darkColor;
    font-weight: 500;
    padding-top: 13px;
    @media (min-width: 320px) and (max-width: 769px) {
      margin: 5px 10px;
    }
  }

  .cancelledHeading {
    font-size: 20px;
    color: $darkColor;
    padding-bottom: 10px;
    @media (min-width: 280px) and (max-width: 769px) {
      padding: 0 3px;
      margin: 10px 5px 0px;
    }
  }

  .tableDiv {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    @media (min-width: 200px) and (max-width: 769px) {
      display: none;
    }
  }
  .tableHead {
    background-color: rgba(248, 249, 251, 1);

    .tableRow {
      color: $GrayColor;
      font-size: 14px;
      letter-spacing: 0px;
    }
  }

  .tableData {
    font-size: 14px;
    height: 45px;
    color: $darkColor;
    .statusOneWay {
      background-color: $blueBadge;
      width: 100%;
    }
    .statusRoundTrip {
      background-color: $darkColor;
      width: 100%;
    }
  }
  .boxIcon {
    height: 14px;
    width: 14px;
    border-radius: 4px;
    border: rgba(198, 208, 220, 1) solid 1px;
    background-color: white;
    margin-bottom: 4px;
  }
  .paginationDiv {
    @media (min-width: 320px) and (max-width: 769px) {
      display: none;
    }
  }
}
