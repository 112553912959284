@import url("https://cdn.syncfusion.com/ej2/material.css");

* {
  font-family: "Inter", sans-serif;
  letter-spacing: 0px;
}

.main {
  height: 111vh;
  display: flex;
  @media (min-width: 280px) and (max-width: 1023px) {
    display: none;
  }

  .myBooking {
    width: 100%;
    @media screen and (min-width: 1024px) {
      margin-left: 20%;
   }
    height: 111vh;
    background-color: #f4f5f7;

    .myBookingContainer {
      width: 100%;

      .myBookingFilter {
        width: 96%;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: auto;

        .type {
          margin: 0;
          color: #272b41;
          font-size: 20px;
          font-weight: 500;
        }

        .filters {
          width: 83%;
          display: flex;
          justify-content: flex-end;

          .search {
            max-width: 263px;
            width: 35%;
            height: 34px;
            display: flex;
            align-items: center;
            color: #ffffff;
            border-radius: 3px;
            margin-right: 10px;
            background-color: #ffffff;
            box-shadow: 0px 3px 5px #9299b80d;
            filter: drop-shadow(0 3px 5px #9299b80d);

            .searchIcon {
              margin: 0 5px 0 10px;
              color: #f7b217;
              font-size: 16px;
              cursor: pointer;
              opacity: 1;
            }

            .searchById {
              width: 80%;
              height: 15px;
              margin: 0;
              outline: none;
              border: none;
              font-size: 12px;
              opacity: 0.5;
              color: #5a5f7d;
              font-weight: 500;
            }
          }

          .selectVehicle,
          .selectDate {
            height: 34px;
            padding: 0 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            opacity: 1;
            border-radius: 3px;
            margin-right: 10px;
            box-shadow: 0px 3px 5px #9299b80d;
            filter: drop-shadow(0 3px 5px #9299b80d);

            .vehicleIcon,
            .eventIcon {
              font-size: 15px;
              color: #f7b217;
            }

            .vehicles,
            .month {
              border: none;
              padding: 0 6px;
              outline: none;
              font-size: 12px;
              font-weight: 500;
              cursor: pointer;
              color: #5a5f7d;
            }
          }

          .addNewBooking {
            width: 150px;
            height: 34px;
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 2px;
            background-color: #f7b217;
            cursor: pointer;
            opacity: 1;
            font-size: 12px;
            font-weight: 500;
            color: #ffffff;
            box-shadow: 0px 3px 5px #20c9970d;
            filter: drop-shadow(0 3px 5px #20c9970d);

            .addNewIcon {
              font-size: 16px;
            }
          }
        }
      }
    }

    .container {
      width: 96%;
      height: 95vh;
      margin: auto;
      background-color: #fff;
      border-radius: 8px;

      .topCont {
        width: 97%;
        height: 65px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > p {
          margin: 0;
          color: #272b41;
          font-size: 18px;
          font-weight: 500;
          font-size: 20px;
          font-weight: 500;
        }
        >div{
          display: flex;
          gap: 20px;
          .completedBookingButton{
            width: 200px;
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #03563d;
            font-weight: 500;
            font-size: 12px;
            background: #e4f6ff;
            border-radius: 3px;
            border: 1px solid #f1f2f6;
            text-decoration: none;
          }
          .cancelBookingButton {
            width: 200px;
            height: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ff4d4f;
            font-weight: 500;
            font-size: 12px;
            background: #fff6f6;
            border-radius: 3px;
            border: 1px solid #f1f2f6;
            text-decoration: none;
          }
        }

        
      }

      .bookings {
        width: 97%;
        margin: auto;
        display: flex;
        justify-content: space-between;

        .applicationStatus {
          width: 19%;
          border-radius: 5px;
          background-color: #f4f5f7;

          .status {
            width: 89%;
            height: 45px;
            display: flex;
            align-items: center;
            margin: auto;

            > p {
              margin: 0;
              font-size: 15px;
              font-weight: 500;
            }
          }
          .bookingInfo:hover{
            overflow-y: scroll;
            
          }
  
          .bookingInfo::-webkit-scrollbar {
            width: 8px;
          }

        
          .bookingInfo::-webkit-scrollbar-track {
            background: #f1f1f1; 
          }
          
         
          .bookingInfo::-webkit-scrollbar-thumb {
            background: rgb(179, 179, 179); 
            border-radius: 10px;
          }

         
          .bookingInfo {
            height: 70vh;
            overflow: hidden;
            margin-bottom: 18px;

            .customers {
              width: 89%;
              margin: auto;
              background-color: #fff;
              border-radius: 5px;
              margin-bottom: 13px;

              > div {
                margin-top: 10px;
              }

              .topSection {
                width: 94%;
                height: 30px;
                margin: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .idNumber {
                  margin: 0;
                  font-size: 12px;
                  font-weight: 700;
                  color: #5a5f7d;
                }
                .topRight{
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  
                  .oneWay {
                    margin: 0;
                    color: #fff;
                    font-size: 8px;
                    font-weight: 600;
                    padding: 4px 3px 3px 3px;
                    border-radius: 3px;
                    margin-right: -7%;
                    background-color: #2c99ff;
                    text-transform: uppercase;
                  }
  
                  .roundTrip {
                    margin: 0;
                    color: #fff;
                    font-size: 8px;
                    font-weight: 600;
                    padding: 4px 3px 3px 3px;
                    border-radius: 3px;
                    background-color: #000;
                    text-transform: uppercase;
                  }
                }
                

                .moreSettings {
                  font-size: 16px;
                }
                .cancelOuterDiv {
                  .modalcancle {
                    font-size: 2px;
                    padding: 0px;
                  }
                }
              }

              .midSection {
                width: 94%;
                margin: auto;

                .name {
                  > p:first-child {
                    margin: 0;
                    color: #272b41;
                    font-weight: 500;
                    font-size: 16px;
                  }

                  > p:last-child {
                    margin: 0;
                    color: #5a5f7d;
                    font-size: 14px;
                    font-weight: 400;
                  }
                  .status {
                    width: 89%;
                    height: 45px;
                    display: flex;
                    align-items: center;
                    margin: auto;

                    > p {
                      margin: 0;
                      font-size: 15px;
                      font-weight: 500;
                    }
                  }

                  .bookingInfo {
                    height: 70vh;
                    overflow-y: scroll;
                    margin-bottom: 18px;

                    .customers {
                      width: 89%;
                      margin: auto;
                      background-color: #fff;
                      border-radius: 5px;
                      margin-bottom: 13px;

                      > div {
                        margin-top: 10px;
                      }

                      .topSection {
                        width: 94%;
                        height: 30px;
                        margin: auto;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .idNumber {
                          margin: 0;
                          font-size: 12px;
                          font-weight: 400;
                          color: #5a5f7d;
                        }

                        .oneWay {
                          margin: 0;
                          color: #fff;
                          font-size: 8px;
                          font-weight: 600;
                          padding: 4px 3px 3px 3px;
                          border-radius: 3px;
                          margin-right: -7%;
                          background-color: #478dcf;
                          text-transform: uppercase;
                        }

                        .roundTrip {
                          margin: 0;
                          color: #fff;
                          font-size: 8px;
                          font-weight: 600;
                          padding: 4px 3px 3px 3px;
                          border-radius: 3px;
                          background-color: #000;
                        }

                        .moreSettings {
                          font-size: 16px;
                        }
                      }

                      .midSection {
                        width: 94%;
                        margin: auto;

                        .name {
                          > p:first-child {
                            margin: 0;
                            color: #272b41;
                            font-weight: 500;
                            font-size: 16px;
                          }

                          > p:last-child {
                            margin: 0;
                            color: #5a5f7d;
                            font-size: 14px;
                            font-weight: 400;
                          }
                        }

                        .data {
                          margin-top: 10px;
                          width: 100%;
                          display: block;

                          > p:first-child {
                            margin: 0;
                            font-size: 14px;
                            color: #6e707f;
                            font-size: 12px;
                            font-weight: 400;
                          }

                          > p:last-child {
                            margin: 0;
                            color: #272b41;
                            font-weight: 500;
                            font-size: 14px;
                          }
                        }

                        .bookingAmt {
                          margin-top: 10px;
                          display: block;

                          > p:first-child {
                            margin: 0;
                            font-size: 14px;
                            color: #6e707f;
                            font-size: 12px;
                            font-weight: 400;
                          }

                          > p:last-child {
                            margin: 0;
                            color: #20c997;
                            font-weight: 700;
                            font-size: 14px;
                          }
                        }

                        .noData {
                          display: none;
                        }
                      }

                      .borderLine {
                        margin-bottom: 0;
                        border: 2px solid #F4F5F7;
                      }

                      .submitSection {
                        width: 94%;
                        margin: auto;

                        .blockAssigned {
                          margin: 0;
                          font-size: 14px;
                          color: #6e707f;
                          font-size: 12px;
                          margin-top: 5px;
                          display: block;
                          font-weight: 400;
                        }

                        .noneAssigned {
                          display: none;
                        }

                        .assignedTo {
                          height: 40px;
                          display: flex;
                          justify-content: flex-start;
                          align-items: center;
                          margin-top: 10px;

                          > img {
                            width: 30px;
                            height: 30px;
                            border-radius: 20px;
                          }

                          > div {
                            margin-left: 10px;

                            > p:first-child {
                              margin: 0;
                              color: #272b41;
                              font-size: 14px;
                              font-weight: 500;
                            }

                            > p:last-child {
                              margin: 0;
                              color: #6e707f;
                              font-size: 12px;
                              font-weight: 400;
                            }
                          }
                        }

                        .notAssignedTo {
                          display: none;
                        }

                        .submit {
                          width: 100%;
                          height: 30px;
                          border: none;
                          margin-top: 13px;
                          margin-bottom: 13px;
                          border-radius: 3px;
                          color: #fff;
                          font-size: 12.5px;
                        }
                      }
                    }
                  }

                  .bookingInfo::-webkit-scrollbar {
                    display: none;
                  }
                }

                .data {
                  width: 100%;
                  margin-top: 10px;
                  display: block;

                  > p:first-child {
                    margin: 0;
                    font-size: 14px;
                    color: #6e707f;
                    font-size: 12px;
                    font-weight: 400;
                  }

                  > p:last-child {
                    margin: 0;
                    color: #272b41;
                    font-weight: 500;
                    font-size: 14px;
                  }
                }

                .bookingAmt {
                  margin-top: 10px;
                  display: block;

                  > p:first-child {
                    margin: 0;
                    font-size: 14px;
                    color: #6e707f;
                    font-size: 12px;
                    font-weight: 400;
                  }

                  > p:last-child {
                    margin: 0;
                    color: #20c997;
                    font-weight: 700;
                    font-size: 14px;
                  }
                }

                .noData {
                  display: none;
                }
              }

              .borderLine {
                margin-bottom: 0;
              }

              .submitSection {
                width: 94%;
                margin: auto;

                .blockAssigned {
                  margin: 0;
                  font-size: 14px;
                  color: #6e707f;
                  font-size: 12px;
                  margin-top: 5px;
                  display: block;
                  font-weight: 400;
                }

                .noneAssigned {
                  display: none;
                }

                .assignedTo {
                  height: 40px;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  margin-top: 10px;

                  > img {
                    width: 30px;
                    height: 30px;
                    border-radius: 20px;
                  }

                  > div {
                    margin-left: 10px;

                    > p:first-child {
                      margin: 0;
                      color: #272b41;
                      font-size: 14px;
                      font-weight: 500;
                    }

                    > p:last-child {
                      margin: 0;
                      color: #6e707f;
                      font-size: 12px;
                      font-weight: 400;
                    }
                  }
                }

                .notAssignedTo {
                  display: none;
                }

                .submit {
                  width: 100%;
                  height: 30px;
                  border: none;
                  margin-top: 13px;
                  margin-bottom: 13px;
                  border-radius: 3px;
                  color: #fff;
                  font-size: 12.5px;
                }
              }
            }
          }
         
        }
      }
    }
  }
}
.modalflex {
  display: flex;
  align-items: center;
}

.modalDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 25px;

  .modalCross {
    border-radius: 100%;
    padding: 0 0.7rem 0.3rem;
    background-color: #f4f5f7;
    margin-bottom: 0.8rem;
    cursor: pointer;
    margin-left: 1rem;
    margin-top: 1rem;
  }

  .oneWay {
    color: #fff;
    font-size: 8px;
    font-weight: 600;
    padding: 4px 3px 3px 3px;
    border-radius: 3px;
    margin-right: -7%;
    background-color: #2c99ff;
    text-transform: uppercase;
  }

  .roundTrip {
    color: #fff;
    font-size: 10px;
    font-weight: 600;
    padding: 4px 3px 3px 3px;
    border-radius: 3px;
    background-color: #000;
    text-transform: uppercase;
  }
}

.midSection {
  margin: auto;

  .name {
    > p:first-child {
      margin: 0;
      color: #272b41;
      font-weight: 500;
      font-size: 16px;
    }

    > p:last-child {
      margin: 0;
      color: #5a5f7d;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .data {
    width: 100%;
    margin-top: 10px;
    display: block;

    > p:first-child {
      margin: 0;
      font-size: 14px;
      color: #6e707f;
      font-size: 12px;
      font-weight: 400;
    }

    > p:last-child {
      margin: 0;
      color: #272b41;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .modalflexInnerPart {
    display: flex;
    width: 100%;
    gap: 40px;
    margin-top: 5px;
    text-align: left;

    .confirmbtn {
      padding: 0.5rem;
      margin-top: 10px;
      border-radius: 0.2rem;
      border: none;
      background: rgb(42, 41, 41);
      color: white;
    }
  }

  .bookingAmt {
    margin-top: 10px;
    display: block;

    > p:first-child {
      margin: 0;
      font-size: 14px;
      color: #6e707f;
      font-size: 12px;
      font-weight: 400;
    }

    > p:last-child {
      margin: 0;
      color: #20c997;
      font-weight: 700;
      font-size: 14px;
    }
  }

  .noData {
    display: none;
  }
}

.popupcontent {
  width: 350px !important;
  height: 300px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 70px 10px rgb(0, 0, 0/11%);
  border-radius: 10px;
  text-align: center;

  .imgbox {
    text-align: center;
    
    .CheckedIcon {
      width: 96px;
      height: 96px;
      margin-top: 15px;
    }
  }

  .sucessmessage {
    margin-top: 30px;
    color: #5A5F7D;
    font-size: 14px;
    font-weight: 500;
  }

  .dashboard {
    margin-top: 5px;
    font-size: 14px;
    color: #5A5F7D;
    font-weight: 400;
  }

  .button {
    margin-top: 20px;
    background-color: #F7B217 ;
    border-radius: 8px;
    width: 213px;
    height: 49px;
    font-size: 14px;
    color: #FFFFFF;
  }
};

.modalcancle {
  font-size: 14px;
  padding: 0px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #9299B83D;
  border-radius: 5px;
  color: #272B41;
  font-weight: 500;
  border: none;
}

.modalBody{
  padding: 0px 10px;
}

.backStateDiv{
   cursor: pointer;
   padding-left: 3px;
}

.notAssignedTo{
   display: none;
}    

.assignedTo {
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;

  > img {
    width: 30px;
    height: 30px;
    border-radius: 20px;
  }

  > div {
    margin-left: 10px;

    > p:first-child {
      margin: 0;
      color: #272b41;
      font-size: 14px;
      font-weight: 500;
    }

    > p:last-child {
      margin: 0;
      color: #6e707f;
      font-size: 12px;
      font-weight: 400;
    }
  }
}