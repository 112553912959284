* {
  font-family: Inter;
  letter-spacing: 0px;
}
$iconColor: #adb4d2;
.updateModelUpper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 1rem 0rem;
  .updateModalTitle {
    font-size: 1.1rem;
    font-weight: bold;
  }
  .updateModalCross {
    border-radius: 100%;
    padding: 0 0.7rem 0.3rem;
    background-color: #f4f5f7;
    margin-bottom: 0.8rem;
    cursor: pointer;
  }
}
.updateButton {
  margin-top: 20px;
}

.mainContainer {
  display: flex;
  background-color: #f4f5f7;
  @media screen and (min-width: 1024px) {
    margin-left: 20%;
  }

  .logincomponent {
    width: 100%;
    padding: 20px;
    .paymentDetailsComponent {
      background-color: white;
      border-radius: 10px;
      padding: 20px 20px 20px 20px;

      .BankAccountDetails {
        margin-bottom: 20px;
        .bankDetailsHeaders {
          display: flex;
          justify-content: space-between;
          max-width: 150px;
          margin-bottom: 10px;
          align-items: center;
          text-align: center;
          .bankDetails {
            font-size: 19px;
            font-weight: 600;
            color: gray;
          }
          .iconStyle {
            color: $iconColor;
            cursor: pointer;
          }
          .upiDetails {
            font-size: 19px;
            font-weight: 600;
            color: gray;
          }
        }
      }
    }
    .paymentDetails {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 10px;
      padding-left: 10px;
    }

    .bgrightloginform {
      border-radius: 10px;
      background-color: white;
      padding: 20px;

      .registerYourAccount {
        .noOutline {
          border: none;
          margin-left: 5px;
        }
        .circleButton {
          margin: auto 0px auto 0px;
        }

        .bankAndUPIDiv {
          margin-left: 20px;
        }

        .withOutline {
          display: block;
          height: 45px;
          width: 55vw;
          padding: 0px 10px 0px 20px;
          border: 1px solid #e3e6ef;
          cursor: pointer;
          align-items: center;
          border-radius: 10px;

          @media only screen and (min-width: 768px) {
            width: 400px;
          }
        }

        .BankAccountDetails {
          width: 63%;
          border: 1px solid #e3e6ef;
          margin-left: 5%;
          margin-bottom: 20px;
          padding: 15px 15px;
          border-radius: 10px;

          @media only screen and (max-width: 767px) {
            width: 94%;
          }

          .inputLabel {
            color: #272b41;
            font-size: 15px;
            font-weight: 500;
          }
        }

        .UpiDetailsDiv {
          margin-top: 15px !important;
          margin-bottom: 15px !important;
        }

        .updateButtonDiv {
          margin: 2% 0% 2% 5%;
          .updateButton {
            padding: auto;
            height: 49px;
            border-radius: 8px;
            font-size: 14px;
            background: #fbbb00;
            border-color: #fbbb00;
            color: #fff;
            border-radius: 4px;
            width: 149px;
            &:hover {
              background: #f7b217;
              border-color: #f7b217;
              color: #fff;
            }
            @media only screen and (max-width: 767px) {
              width: 63%;
            }
            @media only screen and (max-width: 768px) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.bankAndUPIDiv {
  color: #272b41;
  font-size: 15px;
  font-weight: 600;
}

.inputBox {
  border: 1px solid #e3e6ef;
  height: 45px;
  border-radius: 4px;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.inputBox::placeholder {
  color: #adb4d2;
  font-size: 15px;
  font-weight: 400;
}

.uploadIconDiv {
  border: 3px dashed #c6d0dc;
  border-radius: 10px;
  height: 175px;
  width: 175px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #c6d0dc;
  @media (min-width: 250px) and(max-width:320px) {
    height: 164px;
    width: 164px;
  }
  .uploadIcon {
    height: 21.8px;
    width: 21.8px;
  }

  .uploadIcon,
  p {
    font-size: 14px;
  }
}

.crossSign {
  position: relative;
  cursor: pointer;
}
.imageBox {
  width: 100%;
  height: 100%;
}

.updateButton:disabled {
  opacity: 0.4 !important;
  background: #fbbb00 !important;
  border-color: #fbbb00 !important;
  color: #fff !important;
  border-radius: 4px !important;
}

.UpdateinputBox{
   font-size: 14px;
   font-weight: 600 !important;
   color: #272b417c;
}
.UpdateinputBox::placeholder{
   color: #ADB4D2;
   font-weight: normal;
}