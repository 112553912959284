* {
    font-family: Inter;
    letter-spacing: 0px;
}

input::-webkit-outer-spin-button,input::-webkit-inner-spin-button {
        display: none;
      }

.registrationcomponent {
    @media screen and (min-width: 768px) {
        height: 100vh;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin: 0px 0px;
    }

    @media screen and (max-width: 767px) {
        height: 100vh;
        width: 100%;
    }

    .taxiIcon {
        display: none;

        @media only screen and (max-width: 767px) {
            display: block;
            width: 165px;
            height: 37px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 14px;
            margin-bottom: 15px;
        }
    }

    .bgleftimage {
        // height: 100vh;
        // width: 40%;
        // background-size: cover;
        // background-repeat: no-repeat;
        // border-radius: 0px 70px 70px 0px;

        @media screen and (min-width: 1000px) {
            height: 100vh;
            width: 40%;
            background-size: contain;
            background-repeat: no-repeat;
            border-radius: 0px 70px 70px 0px;
        }
    }
    .bgleftimageMediumDiv{
         display: none;
         @media screen and (min-width: 768px) and (max-width: 1000px) {
           width: 70%;
           display: block;
        }
        .bgleftimageMedium{
            display: none;
            @media screen and (min-width: 768px) and (max-width: 1000px) {
                display: block;
                height: 100vh;
                width: 100%;
            }
        };
    };

    .bgrightloginform {
        padding: 0px 15px;
        margin-top: 0px;
        width: 100%;

        @media only screen and (min-width: 768px) {
            width: 65%;
            max-height: 100vh;
            overflow-y: scroll;
        }

        @media only screen and (min-width: 768px) and (max-width: 1000px) {
            padding: 0px 15px;
            width: 80%;
            display: flex;
            flex-direction: column;
            gap:8%;
        }

        .donotaccount {
            display: block !important;
            text-align: end !important;
            color: #909090;
            margin-bottom: 25px;

            .registernow {
                margin-left: 5px;
                padding: 0px;
                border-bottom: 2px solid black;
                font-weight: 600;
                color: black;
            }


            @media only screen and (min-width: 768px) {
                margin-top: 15px;
            }

        }

        ;

        .registeraccount {
            font-size: 24px;
            color: #272B41;
            margin-top: 10%;


            @media only screen and (max-width: 767px) {
                margin-top: 10px;
            }
        }

        .tellbusinessdiv {
            .tellbusiness {
                font-size: 13px;
                letter-spacing: 0px;
                color: #5A5F7D;
            }

            .step1 {
                margin-top: 0px;
                font-size: 13px;
                color: #272B41;
                font-weight: 600;
            }
        }

        @media only screen and (max-width: 768px) {
            .inputbox {
                width: 100%;
                .inputlabel {
                    font-size: 14px;
                    color: #272B41;
                    font-weight: 600;
                }

                .input {
                    height: 40px;
                    border: 1px solid #E3E6EF;
                    border-radius: 5px;
                    padding: 0 0 0 1rem;

                    &:focus {
                        border: 0 !important;
                    }

                    ::placeholder {
                        color: #ADB4D2;
                        font-size: 14px;
                        font-weight: 400;
                    }

                }

                .eyeStyle {
                    opacity: 0.6;
                    z-index: 10;
                    cursor: pointer;
                    margin-top: 5px;
                    margin-left: -40px;
                    border: none;
                    background-color: transparent;
                }
            }
        }

        @media only screen and (min-width: 767px) and (max-width:1023px){
            .inputbox {
                width: 100%;
                .inputlabel {
                    font-size: 14px;
                    color: #272B41;
                    font-weight: 600;
                }

                .input {
                    height: 40px;
                    border: 1px solid #E3E6EF;
                    border-radius: 5px;
                    padding: 0 0 0 1rem;

                    &:focus {
                        border: 0 !important;
                    }

                    ::placeholder {
                        color: #ADB4D2;
                        font-size: 14px;
                        font-weight: 400;
                    }

                }

                .eyeStyle {
                    opacity: 0.6;
                    z-index: 10;
                    cursor: pointer;
                    margin-top: 5px;
                    margin-left: -40px;
                    border: none;
                    background-color: transparent;
                }
            }
        }

        @media only screen and (min-width: 1024px) {
            .inputbox {
                width: 50%;
                .inputlabel {
                    font-size: 14px;
                    color: #272B41;
                    font-weight: 600;
                }

                .input {
                    height: 40px;
                    border: 1px solid #E3E6EF;
                    border-radius: 5px;
                    padding: 0 0 0 1rem;

                    &:focus {
                        border: 0 !important;
                    }

                    ::placeholder {
                        color: #ADB4D2;
                        font-size: 14px;
                        font-weight: 400;
                    }

                }

                .eyeStyle {
                    opacity: 0.6;
                    z-index: 10;
                    cursor: pointer;
                    margin-top: 5px;
                    margin-left: -40px;
                    border: none;
                    background-color: transparent;
                }
            }
        }



        .inputdiv {
            .inputlabel {
                font-size: 14px;
                color: #272B41;
                font-weight: 600;
            }

            .inputbox {
                height: 40px;
                border: 1px solid #E3E6EF;
                border-radius: 5px;
                padding: 0 0 0 1rem;
                display: flex;
                align-items: stretch;
                border-radius: 4px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
            }

            ::placeholder {
                color: #ADB4D2;
                font-size: 14px;
                font-weight: 400;
            }

            .eyeStyle {
                cursor: pointer;
                margin-left: -35px;
                border: none;
                background-color: transparent;
            }
        }

        .termpolicydiv {
            .termpolicy {
                .termpolicylabel {
                    font-size: 13px;
                    color: #9299B8;

                    .termpolicyspan {
                        margin: 0px;
                        font-weight: 400;
                        color: #46474C;
                        font-size: 13px;
                        padding: 0px 5px 0px 4px;
                    }
                }
            }
        }

        .continue {
            .continuebutton {
                width: 161px;
                border-radius: 8px;
                background: #F7B217 0% 0% no-repeat padding-box;
                height: 49px;
                color: #FFFFFF;
                font-size: 14px;

                @media screen and (max-width: 767px) {
                    width: 100%;
                    max-width: 100%;
                    margin: auto;
                    align-content: center;
                }
            }
        }

        .formBox {
            width: 50%;
            margin: auto;

            @media only screen and (max-width: 767px) {
                width: 80%;
            }
        }

        .or {
            width: 100%;
            text-align: center;
            border-bottom: 1px solid #000;
            line-height: 0.1em;
            margin: 30px 0 20px;
        }

        span {
            background: #fff;
            padding: 0 10px;
        }

        .signinwithmobile {
            width: 300px;
            border: 4px;
            height: 50px;
            border-radius: 8px;
            margin: 30px auto;
            display: block;
        }
    }
}

.registrationForm{
    @media only screen and (min-width: 767px) and (max-width:1023px){
        padding: 0px 20px 0px 20px;
    }

     @media only screen and (min-width: 1024px){
        padding: 0px 100px 0px 100px;
     }
};

.TaxiCRMImageDiv {
    display: none;
    width: 40%;
    height: 100vh;
    background: url(../../assets/images/Left-BackGround.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0px 70px 70px 0px;
    padding: 20px 10px 0px 30px;

    @media only screen and(min-width:1000px){
        background-size: cover;
        width: 35%;
    }

    @media only screen and (min-width:768px){
        display: block;
    };

    .IconAndText {
        margin-top: 20vh;

        @media only screen and (min-width:1000px) {
            margin-top: 10vh;
            margin-left: 5%;
        }

        .imageText {
            @media only screen and (min-width:768px) and (max-width:800px) {
                font-size: 28px;
            }
            color: #272B41;
            font-size: 30px;
            font-weight: 600;
            margin-top: 30px;
        }
    };

    .TaxiLeftImage {
        width: 60%;
        margin: auto;
        margin-top: 15vh;

        @media only screen and (min-width:768px) and (max-width:999px) {
            width: 65%;
            margin: auto;
            margin-top: 10vh;
        }
    }

};