* {
  font-family: Inter;
  letter-spacing: 0px;
}

.logincomponent {
  @media screen and (min-width: 768px) {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    align-items: center;
  }

  .bgleftimage {
    // height: 100vh;
    // width: 40%;
    // background-size: cover;
    // background-repeat: no-repeat;
    // border-radius: 0px 70px 70px 0px;

    @media screen and (min-width: 1000px) {
      height: 100vh;
      width: 35%;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 0px 70px 70px 0px;
    }
  }

  // .bgleftimageMediumDiv {
  //     display: none;

  //     @media screen and (min-width: 768px) {
  //         width: 70%;
  //         display: block;
  //     }

  //     .bgleftimageMedium {
  //         display: none;

  //         @media screen and (min-width: 768px) {
  //             display: block;
  //             height: 100vh;
  //             width: 100%;
  //         }
  //     }
  // }

  .taxiIcon {
    display: none;

    @media only screen and (max-width: 767px) {
      display: block;
      width: 165px;
      height: 37px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 25px;
      margin-bottom: 60px;
    }
  }

  .bgrightloginform {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80vh;
    @media only screen and (min-width: 768px) {
      width: 60%;
      margin: auto 40px;
    }

    .formBox {
      @media only screen and (max-width: 767px) {
        width: 100%;
        padding: 25px;
      }

      @media screen and (min-width: 1000px) {
        margin: auto;
        width: 60%;
      }

      .loginCRM {
        font-family: Inter;
        font-weight: bold;
        font: normal normal bold 24px/30px Inter;
        letter-spacing: 0px;
        color: #272b41;
      }

      .inputbox {
        .inputlabel {
          font-size: 14px;
          color: #272b41;
          font-weight: 600;
        }

        .input {
          height: 40px;
          border: 1px solid #e3e6ef;
          border-radius: 5px;
          padding: 0 0 0 1rem;

          &:focus {
            border: 0 !important;
          }

          ::placeholder {
            color: #adb4d2;
            font-size: 14px;
            font-weight: 400;
          }
        }

        .eyeStyle {
          opacity: 0.6;
          z-index: 10;
          cursor: pointer;
          position: absolute;
          right: 0;
          margin-top: 13px;
          border: none;
          background-color: transparent;
        }
      }

      .errormessage {
        font-size: 14px;
        margin-bottom: 8px;
        margin-top: 0px;
      }

      .sendcode {
        width: 214px;
        height: 49px;
        background: #f7b217 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 5px #20c9970d;
        color: #ffffff;
        font-size: 14px;
        font-weight: 600;

        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }

    .signinbutton {
      background: #f7b217 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 5px #20c9970d;
      width: 185px;
      height: 48px;
      color: #ffffff;

      @media only screen and (max-width: 767px) {
        width: 100%;
        margin: auto;
      }
    }

    span {
      background: #fff;
      padding: 0 10px;
    }
  }
}

.TaxiCRMImageDiv {
  display: none;
  width: 35%;
  height: 100vh;
  background: url(../../assets/images/Left-BackGround.png);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0px 70px 70px 0px;
  padding: 20px 10px 0px 30px;

  @media only screen and(min-width:1000px) {
    background-size: cover;
  }

  @media only screen and (min-width: 768px) {
    display: block;
  }

  .IconAndText {
    margin-top: 20vh;

    @media only screen and (min-width: 1000px) {
      margin-top: 10vh;
      margin-left: 5%;
    }

    .imageText {
      @media only screen and (min-width: 768px) and (max-width: 800px) {
        font-size: 28px;
      }
      color: #272b41;
      font-size: 30px;
      font-weight: 600;
      margin-top: 30px;
    }
  }

  .TaxiLeftImage {
    width: 60%;
    margin: auto;
    margin-top: 15vh;

    @media only screen and (min-width: 768px) and (max-width: 999px) {
      width: 65%;
      margin: auto;
      margin-top: 10vh;
    }
  }
}
