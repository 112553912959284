* {
    font-family: Inter;
    letter-spacing: 0px;
}

.otpcomponent {
    @media screen and (min-width: 768px) {
        height: 100vh;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0px -15px;
    }

    @media screen and (max-width: 767px) {
        width: 100%;
        align-items: center;
        margin: 0px -15px;
    }

    .bgleftimage {
        // height: 100vh;
        // width: 40%;
        // background-size: cover;
        // background-repeat: no-repeat;
        // border-radius: 0px 70px 70px 0px;

        @media screen and (min-width: 1000px) {
            height: 100vh;
            width: 40%;
            background-size: contain;
            background-repeat: no-repeat;
            border-radius: 0px 70px 70px 0px;
        }
    }

    .bgleftimageMediumDiv {
        display: none;

        @media screen and (min-width: 768px) and (max-width: 1000px) {
            width: 70%;
            display: block;
        }

        .bgleftimageMedium {
            display: none;

            @media screen and (min-width: 768px) and (max-width: 1000px) {
                display: block;
                height: 100vh;
                width: 100%;
            }
        }

        ;
    }

    ;

    .taxiIcon {
        display: none;

        @media only screen and (max-width: 767px) {
            display: block;
            width: 165px;
            height: 37px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 24px;
            margin-bottom: 60px;
        }
    }

    .otpform {
        width: 100%;

        @media only screen and (min-width: 768px) {
            width: 65%;
        }

        .formBox {
            width: 50%;
            margin: auto;

            @media only screen and (max-width: 999px) {
                width: 80%;
            }

            .inputbox {
                margin-top: 25px;

                .inputlabel {
                    font-size: 14px;
                    color: #272B41;
                    font-weight: 600;
                }

                .input {
                    height: 40px;
                    border: 1px solid #E3E6EF;
                    border-radius: 5px;
                    padding: 0 0 0 1rem;

                    &:focus {
                        border: 0 !important;
                    }

                    ::placeholder {
                        color: #ADB4D2;
                        font-size: 14px;
                        font-weight: 400;
                    }

                    ;
                }

                ;
            }

            ;

            .loginCRM {
                font-family: Inter;
                font-weight: bold;
                letter-spacing: 0px;
                color: #272B41;
            }

            .mobilelogin {
                font-size: 24px;
                font-weight: 700;
                color: #272B41;
            }

            .instruction {
                color: #5A5F7D;
                font-size: 14px;
                font-weight: 400;
            }

            .emailaddresslabel {
                font-size: 14px;
                letter-spacing: 0px;
                font-weight: 600;
                color: #272B41;
                margin-top: 10px;
            }

            .emailaddress {
                font-size: 14px;
                margin-bottom: -10px;
                border: 1px solid #E3E6EF;
                border-radius: 4px;
            }

            ::placeholder {
                color: #ADB4D2;
                font-size: 14px;
                font-weight: 400;
            }
        }

        .mobilenumber {
            color: #272B41;
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 5px;
        }

        .mobileinputbox {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 5px 20px #5F63F21A;
            border: 1px solid #F7B217;
            border-radius: 4px;
        }

        ::placeholder {
            color: #ADB4D2;
            font-size: 14px;
            font-weight: 400;
        }

        .errormessage {
            font-size: 14px;
        }

        .sendcode {
            width: 214px;
            height: 49px;
            background: #F7B217 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 5px #20C9970D;
            color: #FFFFFF;
            font-size: 14px;
            font-weight: 600;
            margin-top: 20px;

            @media screen and (max-width: 767px) {
                width: 100%;
            }
        }
    }

    .keeplogged {
        margin-top: 20px;
        color: #9299B8;
        font-size: 13px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .forgotpassword {
            font-size: 14px;
            color: #272B41;
            font-weight: 600;
            text-decoration: underline;
        }
    }

    .signinbutton {
        background: #F7B217 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 5px #20C9970D;
        width: 185px;
        height: 48px;
        color: #FFFFFF;


    }

    .or {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.164);
        line-height: 0.1em;
        margin: 30px 0 20px;
        font-size: 13px;
        color: #9299B8;
    }

    span {
        background: #fff;
        padding: 0 10px;
    }

    .signinwithmobile {
        width: 259px;
        border: 4px;
        height: 45px;
        letter-spacing: 0px;
        background: #F8F9FB 0% 0% no-repeat padding-box;
        border: 1px solid #F1F2F6;
        font-size: 14px;
        font-weight: 600;
        color: #272B41;
        border-radius: 8px;
        margin: 30px auto;
        display: block;

    }
}

.returnToLogin {
    color: #5A5F7D;
    font-size: 14px;
    margin-top: 25px;

    @media only screen and (max-width: 767px) {
        display: flex;
        justify-content: center;
    }

    .loginLink {
        font-size: 14px;
        letter-spacing: 0px;
        font-weight: 600;
        color: #272B41;
        padding: 0px 0px 0px 5px;
        text-decoration: underline;

    }
}

.TaxiCRMImageDiv {
    display: none;
    width: 40%;
    height: 100vh;
    background: url(../../../assets/images/Left-BackGround.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0px 70px 70px 0px;
    padding: 20px 10px 0px 30px;

    @media only screen and(min-width:1000px){
        width: 35%;
        background-size: cover;
    }

    @media only screen and (min-width:768px){
        display: block;
    };

    .IconAndText {
        margin-top: 20vh;

        @media only screen and (min-width:1000px) {
            margin-top: 10vh;
            margin-left: 5%;
        }

        .TaxiIconImage {}

        .imageText {
            @media only screen and (min-width:768px) and (max-width:800px) {
                font-size: 28px;
            }
            color: #272B41;
            font-size: 30px;
            font-weight: 600;
            margin-top: 30px;
        }
    };

    .TaxiLeftImage {
        width: 60%;
        margin: auto;
        margin-top: 15vh;

        @media only screen and (min-width:768px) and (max-width:999px) {
            width: 65%;
            margin: auto;
            margin-top: 10vh;
        }
    }

}