*{
    font-family: Inter;
}
$badgeColor: #33cea0;
.driversGrid {
    max-height: 60%;
    width: 100%;
    box-sizing: border-box;
    overflow: scroll;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 100px;
    grid-auto-rows: 100px;
    grid-gap: 20px;
    
    @media only screen and (min-width: 768px) {
      max-height: 230px;
      overflow: auto;
   };

    .vehicleName {
      font-size: 0.9rem;
    }
    .cardDiv {
        height: 100%;
        width: 100%;
        margin-bottom:0px !important;
        border: none;
        // background-color: #FFFFFF;
        // border: 1px solid black;
        color: #272B41;
        font-family: Inter medium;
        .cardInnerDiv{
            height: 50%;
            margin-top:0px;
            box-shadow: 5px 5px 20px #9299B81F;
            border-radius: 10px;
            gap: 10px;
            .carImage{
                width: 80px;
                height: 80px;
                border-radius: 50%;
            }
            .carDescription{
                text-align: left;
                .carNumber{
                    font-weight: 600;
                }
            }
            .carDelete{
                color: #F46262;
                height: 22px;
                width: 18px;
            }
        }
      }
    .activeBadge {
      color: $badgeColor;
      border-radius: 1rem;
    }
    .detailsHeadings {
      color: gray;
      font-size: 0.6rem;
      height: 0px;
      margin-top: 0.5rem;
    }
    .detailsContent {
      width: 100%;
      height: 0px;
      margin-top: 1rem;
      font-size: 0.7rem;

      @media (min-width: 769px) and (max-width: 1126px) {
        width: 100%;
      }
    }
  }

  @media (max-width: 1023px) {
    .driversGrid {
        max-height: 210px;
        width: 100%;
        margin-bottom:0px !important;
        box-sizing: border-box;
        overflow: scroll;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: 100px;
        grid-auto-rows: 100px;
        grid-gap: 10px;
        .cardDiv {
            margin-left: 0px;
            box-shadow: 0px 5px 20px #9299B81F;
            .cardInnerDiv{
                margin-top:0px;
                .carImage{
                    width: 4.3rem;
                    border-radius: 50%;
                }
                .carDescription{
                    margin-left:5%;
                    text-align: left;
                    font-size: medium;
                    .carNumber{
                        font-weight: 600;
                    }
                }
                .carDelete{
                    color: #F46262;
                    height: 22px;
                    width: 18px;
                }
            }
        }
        
  }
}

.cardInfo{
   justify-content: space-between;
   max-width: 1023px;
}
