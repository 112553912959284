.navbarMain{
  position: fixed!important;
  width: 100vw!important;
  z-index: 1000;
}

.navbarComponent {
  .navbarBrandleft {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;

    .navbarMenu {
      display: none;

      @media (min-width: 280px) and (max-width: 1023px) {
        display: flex;
      }
    }
  }

  ;

  .iconsDiv {
    gap: 20px;
    margin-top: 10px;

    .dropdownbtn {
      margin-top: -3px;
      margin-left: -10px;

      .profileIcon {
        color: #FFFFFF;
        background-color: #F7B217;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        padding: 8px;
      }
    }
  };

  .navbaricon {
    width: 54px;
    height: 37px;

    @media (min-width: 1024px) {
      display: none;
    }
  }

  .desktopIcon {
    @media (min-width: 280px) and (max-width: 1023px) {
      display: none;
    }
  }

  .bellicon {
    width: 18px;
    height: 18px;
  }

  .settingicon {
    width: 18px;
    height: 18px;
  }

  .profileicon {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    cursor: pointer;
    // background: #f7b217 0% 0% no-repeat padding-box;

    .profileiconimg {
      color: white;
    }
  }
}

;

.dropdown-toggle {
  display: flex;
}