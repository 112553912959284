* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

$badgeColor: #20c997;
$iconColor: #adb4d2;
$lightGray: #9299b8;

.mainContainer {
  padding: 0px 5px 0px 10px;
  width: 100%;
  @media screen and (min-width: 1024px) {
    margin-left: 20%;
  }
  .addDriverBtnDiv {
    @media (min-width: 280px) and (max-width: 1023px) {
      display: none;
    }
    .addDriverBtn {
      color: white;
      padding: 5px 25px;
    }
  }
  .contentDiv {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    margin: 6px 1px;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    padding: 12px 12px;
    height: 100%;

    .nameTitle {
      font-size: 16px;
      color: #272b41;
      font-weight: 500;
    }

    .badge {
      color: $badgeColor;
      border-radius: 15px;
      background-color: #dcf6ee;
      font-size: 11px;
      font-weight: 600;
      padding: 1px 6px;
      text-transform: uppercase;
    }

    .deactiveBadge {
      color: red;
      border-radius: 15px;
      background-color: lightpink;
      text-transform: uppercase;
    }

    .iconStyle {
      color: $iconColor;
      cursor: pointer;
    }

    .detailsSection {
      // width: 65%;
      .otherContentTitle {
        padding: 0px;
        margin: 0px;
        font-size: 12px;
        color: $lightGray;
      }
      .otherContentValue {
        font-size: 12px;
        padding-left: 0.1rem;
        font-weight: 600 !important;
        color: #272b41;
      }
    }
  }
}
// ---------modal--------

.modalDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 1rem 0rem;

  .modalTitle {
    font-size: 1.1rem;
    font-weight: bold;
  }

  .modalCross {
    // border: 1px solid gray;
    border-radius: 100%;
    padding: 0 0.7rem 0.3rem;
    background-color: #f4f5f7;
    margin-bottom: 0.8rem;
    cursor: pointer;
  }
}

.selectBox {
  width: 100%;
  height: 48px;
  padding: 0 0px;
  border: 1px solid #e3e6ef;
  border-radius: 5px;
  margin-bottom: 14px;

  .select {
    border: none;
    width: 100%;
    height: 100%;
    outline: none;
    color: black;

    // &:focus:valid {
    //   color: black;
    // }
  }
}

.buttonBox {
  .addDriver {
    background-color: #f7b217;
    border-color: #b48519;
    color: rgb(255, 255, 255);
    &:hover {
      background-color: #f7b217;
      color: white;
      cursor: pointer;
    }
  }

  .closeBtn {
    background: #ffffff;
    border: 1px solid #e3e6ef;
    border-radius: 5px;
    color: #9299B8;
    padding: 0 1rem;
    cursor: pointer;

    &:hover {
      background-color: #9299b8;
      border-color: #9299b8;
      color: #eef0f5;
    }
  }
}

// input tags:-

.labelFont {
  font-size: 15px;
}

.uploadRC {
  width: 48%;
}

.uploadVImg {
  width: 30%;
}

.profileIcons {
  color: #f8f9fb;
  width: 4rem;
  height: 4rem;
  border: 1px dashed #adb4d2;
  border-radius: 0.5rem;
  padding: 1rem;
}

.upload {
  border: 1.5px dashed;
  border-color: #c6d0dc;
  border-radius: 10px;
  border-width: 2.5px;
  margin-top: 10px;
  width: 100%;
}
.uploadbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
  gap: 6px;
}

.uploadboxVehicleImages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 110px;
}

.uploadText {
  text-align: center;
  color: #c6d0dc;
  font-size: 14px;
}

.uploadIcon {
  height: 32px;
  width: 69px;
  color: #c6d0dc;
}
.uploadSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;
  gap: 6px;
}

.uploadIconSmall {
  height: 100%;
  width: 100%;
  color: #c6d0dc;
}

.uploadIconSymbol {
  height: 34px;
  width: 32px;
  color: #c6d0dc;
}

.icons {
  color: #c6d0dc;
  width: 100px;
  height: 92px;
  border: 1.5px dashed #c4c9df;
  background-color: #c6d0dc;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-bottom: 1rem;
}
.addBtn {
  color: white;
}
.closeBtn {
  background-color: white;
  border: 1px solid #e3e6ef;
  border-radius: 5px;
  color: #9299B8;
  padding: 0 1rem;
}

.input {
  height: 48px;
  border: 1px solid #e3e6ef;
  border-radius: 5px;
  opacity: 1;
  padding: 0 0 0 1rem;
  font-size: 14px;
}

.fiRCUploadImage {
  height: 100%;
  width: 100%;
}

$grayColor: #e3e6ef;
$activeTab: #f7b217;
$darkBlack: #272b41;

@media (min-width: 280px) and (max-width: 1023px) {
  .addBookingDiv {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background-color: $activeTab;
    position: fixed;
    bottom: 25px;
    right: 25px;
  }
}

.addBookingIcon {
  font-size: 20px;
  color: $grayColor;
  @media only screen and (min-width: 1024px) {
    display: none;
  }
}

.crossSign {
  position: relative;
  cursor: pointer;
}
.imageBox {
  width: 100%;
  height: 100%;
}

.addDriver:disabled {
  opacity: 0.5 !important;
  background-color: #f7b217 !important;
  border-color: #b48519 !important;
  color: rgb(255, 255, 255) !important;
};

.pageTitle {
  display: flex;
  justify-content: left;
  margin-top: 15px;
};
