* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

$badgeColor: #20c997;
$iconColor: #adb4d2;
$lightGray: #9299b8;

.mainContainer {
  padding: 0px 5px 0px 10px;
  width: 100%;
  @media (min-width: 280px) and (max-width: 320px) {
    padding: 0px 0px 0px 0px;
  }
  @media screen and (min-width: 1024px) {
    margin-left: 20%;
  }
  .addDriverBtnDiv {
    @media (min-width: 280px) and (max-width: 1023px) {
      display: none;
    }
    .addDriverBtn {
      color: white;
      padding: 5px 25px;
    }
  }
  .contentDiv::-webkit-scrollbar {
    display: none;
  }

  .contentDiv {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    // width: 100vh;
    background-color: white;
    border-radius: 10px;
    padding: 12px 6px;
    min-height: 100px;
    height: 100%;
    display: flex;
    overflow: scroll;
    gap: 5px;
    align-items: center;
    @media (min-width: 280px) and (max-width: 1023px) {
      gap: 9px;
    }
    .driverImageDiv {
      > img {
        height: 5vh;
        width: 3vw;
        border-radius: 50%;
        @media (min-width: 280px) and (max-width: 1023px) {
          height: 5vh;
          width: 9vw;
        }
      }
    }

    .profileDiv {
      flex: 1;
    }

    .nameTitle {
      font-size: 16px;
      margin: 0;
      color: #272b41;
      font-weight: 500;
    }

    .badge {
      color: $badgeColor;
      border-radius: 15px;
      background-color: #dcf6ee;
      font-size: 11px;
      font-weight: 600;
      padding: 1px 6px;
      text-transform: uppercase;
    }

    .deactiveBadge {
      color: red;
      border-radius: 15px;
      background-color: lightpink;
      text-transform: uppercase;
    }

    .iconStyle {
      color: $iconColor;
      cursor: pointer;
    }

    .detailsSection {
      width: 100%;

      @media (min-width: 280px) and (max-width: 1023px) {
        flex-direction: column;
      }

      .otherContentTitle {
        padding: 0px;
        margin: 0px;
        font-size: 12px;
        color: $lightGray;
      }

      .otherContentValue {
        font-size: 12px;
        margin: 0;
        font-weight: 600;
        color: #272b41;
      }
    }
  }
}

// ---------modal--------

.modalDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 1rem 0rem;

  .modalTitle {
    font-size: 1.1rem;
    font-weight: bold;
  }

  .modalCross {
    // border: 1px solid gray;
    border-radius: 100%;
    padding: 0 0.7rem 0.3rem;
    background-color: #f4f5f7;
    margin-bottom: 0.8rem;
    cursor: pointer;
  }
}

// input tags:-
.labelFont {
  font-size: 0.9rem;
}

.profileIconImage {
  color: #f8f9fb;
  width: 100px;
  height: 100px;
  border: 1px dashed #adb4d2;
  border-radius: 0.5rem;
}

.uploadImage {
  height: 45px;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  max-width: 220px;
  align-items: center;
  border: 1px solid #c4c9df;
  border-radius: 0.2rem;
  padding: 0.75rem 0.8rem;
  margin: auto;
  color: #9299b8;
  opacity: 1;
  text-align: center;

  @media (min-width: 280px) and (max-width: 320px) {
    height: auto;
  }
  // @media only screen and (min-width: 768px) {
  //   padding: 0.4rem 1.4rem;
  // }
}

.icons {
  color: #9299b8;
  width: 100px;
  height: 100px;
  border: 1.5px dashed #c4c9df;
  background-color: #f8f9fb;
  border-radius: 0.5rem;
  border-width: 3.5px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.uploadImageInput {
  background-color: red;
}

.input {
  height: 48px;
  border: 1px solid #e3e6ef;
  border-radius: 5px;
  opacity: 1;
  padding: 0 0 0 1rem;
}

::-webkit-input-placeholder {
  color: #adb4d2;
}

.addBtn {
  color: white;
}

.buttonBox {
  margin-top: 21px;
  .addDriver {
    width: auto;
    height: 40px;
    padding: 0px 10px;
    background-color: #f7b217;
    border-radius: 5px;
    cursor: pointer;
    border-color: #f7b217;
    color: rgb(255, 255, 255);
    @media (min-width: 280px) and (max-width: 320px) {
      height: auto;
    }

    &:hover {
      background-color: #f7b217;
      border: none;
      color: white;
      cursor: pointer;
    }
  }

  .closeBtn {
    background: #ffffff;
    color: #9299b8;
    border: 1px solid #e3e6ef;
    border-radius: 5px;
    padding: 0 1rem;
    cursor: pointer;

    &:hover {
      border-color: #9299b8;
    }
  }
}

.labelFont {
  font-size: 15px;
}

.profileIcons {
  color: #f8f9fb;
  width: 4rem;
  height: 4rem;
  border: 1px dashed #adb4d2;
  border-radius: 0.5rem;
  padding: 1rem;
}

.uploadLisence {
  width: 48%;
}

.upload {
  border: 1.5px dashed;
  border-color: #c6d0dc;
  border-radius: 10px;
  border-width: 2.5px;
  margin-top: 10px;
  width: 100%;
}

.uploadbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
  gap: 6px;
}

.uploadboxVehicleImages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 118px;
  height: 110px;
}

.uploadText {
  text-align: center;
  color: #c6d0dc;
  font-size: 14px;
}

.uploadIcon {
  height: 32px;
  width: 69px;
  color: #c6d0dc;
}

.fiRCUploadImage {
  height: 100%;
  width: 100%;
}

$grayColor: #e3e6ef;
$activeTab: #f7b217;
$darkBlack: #272b41;

@media (min-width: 280px) and (max-width: 1023px) {
  .addBookingDiv {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background-color: $activeTab;
    position: fixed;
    bottom: 25px;
    right: 25px;
  }
}

.addBookingIcon {
  font-size: 20px;
  color: $grayColor;
  @media only screen and (min-width: 1024px) {
    display: none;
  }
}
.crossSign {
  position: relative;
  cursor: pointer;
}
.imageBox {
  width: 100%;
  height: 100%;
}
.pageTitle {
  display: flex;
  justify-content: left;
  margin-top: 15px;
}

.addDriver:disabled {
  opacity: 0.5 !important;
}

.driverImageNameDiv {
  width: 50%;
}
