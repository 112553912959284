.logincomponent {
  padding: 0px 0px;

  @media screen and (min-width: 768px) {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 0px 0px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  .taxiIcon {
    display: none;

    @media only screen and (max-width: 767px) {
      display: block;
      width: 165px;
      height: 37px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 14px;
      margin-bottom: 30px;
    }
  }

  .bgleftimage {
    // height: 100vh;
    // width: 40%;
    // background-size: cover;
    // background-repeat: no-repeat;
    // border-radius: 0px 70px 70px 0px;

    @media screen and (min-width: 1000px) {
      height: 100vh;
      width: 40%;
      background-size: contain;
      background-repeat: no-repeat;
      border-radius: 0px 70px 70px 0px;
    }
  }

  .bgleftimageMediumDiv {
    display: none;

    @media screen and (min-width: 768px) and (max-width: 1000px) {
      width: 70%;
      display: block;
    }

    .bgleftimageMedium {
      display: none;

      @media screen and (min-width: 768px) and (max-width: 1000px) {
        display: block;
        height: 100vh;
        width: 100%;
      }
    }
  }

  .bgrightloginform {
    width: 100%;

    @media only screen and (min-width: 768px) {
      width: 70%;
      height: 100vh;
      overflow-y: scroll;
    }

    .donotaccount {
      text-align: end;
      margin-right: 0px;
      margin-top: 2%;
      color: #909090;
      padding-right: 8px;

      @media only screen and (min-width: 768px) {
        margin-right: 10px;
      }

      .alreadyaccount {
        color: #5a5f7d;
        font-size: 14px;
        font-weight: 600;

        .signin {
          font-weight: 600;
          margin-left: 7px;
          color: #272b41;
          text-decoration: underline;
          font-size: 14px;
        }
      }
    }

    .registerYourAccount {
      margin-top: 10%;
      margin-left: 10px;
      @media only screen and (max-width:767px){
          padding: 20px;
      }

      @media only screen and (min-width: 1024px) {
        padding: 0px 130px 0px 130px;
        margin: 7% auto 0 auto;
      }

      @media only screen and (min-width: 768px) and (max-width: 1023px) {
        margin: 30px 20px;
        // margin-top: 20%;
      }

      .registeraccount {
        font-size: 24px;
        color: #272b41;
        font-weight: 700;
      }

      .addreviewdiv {
        margin: 10px 0px;
        align-items: center;
        .addreview {
          font-size: 12px;
          color: #5a5f7d;
          font-weight: 400;
          @media only screen and (min-width: 768px) {
            font-size: 14px;
          }
        }

        .step4 {
          color: #272b41;
          font-size: 12px;
          font-weight: 600;
          @media only screen and (min-width: 767px) {
            font-size: 14px;
          }
        }

        @media only screen and (max-width: 767px) {
          margin-right: 6%;
          margin-top: 15px;
        }
      }

      .icondiv {
        color: #272b41;
        font-size: 14px;
        font-weight: 600;

        @media only screen and (max-width: 767px) {
          width: 95%;
        }

        .facebook {
          width: 44px;
          height: 44px;
          padding: 12px 17px;
          color: white;
          background: #3b5998 0% 0% no-repeat padding-box;
          border-radius: 4px;
        }

        .justDial {
          width: 44px;
          height: 44px;
          padding: 5px 8px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #fa8b0c;
          border-radius: 4px;
        }

        .google {
          width: 44px;
          height: 44px;
          padding: 12px 17px;
          background-color: #2b8dff;
          color: #ffffff;
          border-radius: 5px;
        }

        .tripadvisor {
          width: 44px;
          height: 44px;
          padding: 5px 10px;
          color: #000000;
          background-color: #34e0a1;
          border-radius: 5px;
        }

        .iconinput {
          border: 1px solid #e3e6ef;
          border-radius: 4px;
          width: 40%;
        }

        ::placeholder {
          color: #9299b8;
          font-size: 13px;
          font-weight: 400;
        }
      }

      .errormessage {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .buttons {
        gap: 18px;
        margin-top: 35px;
        display: flex;
        flex-wrap: wrap;

        .back {
          border: 1px solid #e3e6ef;
          border-radius: 5px;
          background: #ffffff 0% 0% no-repeat padding-box;
          color: #9299b8;
          font-weight: 600;
          font-size: 14px;
          height: 49px;
          padding: 10px 20px;
          @media only screen and (max-width:370px){
            padding: 10px 10px;
          }
        }

        .dolater {
          padding: 10px 15px;
          @media only screen and (max-width:370px){
            padding: 10px 5px;
          }
          background: #f7b217 0% 0% no-repeat padding-box;
          border-radius: 8px;
          color: #ffffff;
          font-size: 14px;
          font-weight: 600;
        }

      }
    }
  }
}

.TaxiCRMImageDiv {
  display: none;
  width: 40%;
  height: 100vh;
  background: url(../../../assets/images/Left-BackGround.png);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0px 70px 70px 0px;
  padding: 20px 10px 0px 30px;

  @media only screen and(min-width:1000px) {
    background-size: cover;
    width: 35%;
  }

  @media only screen and (min-width: 768px) {
    display: block;
  }

  .IconAndText {
    margin-top: 20vh;

    @media only screen and (min-width: 1000px) {
      margin-top: 10vh;
      margin-left: 5%;
    }

    .TaxiIconImage {
    }

    .imageText {
      @media only screen and (min-width: 768px) and (max-width: 800px) {
        font-size: 28px;
      }
      color: #272b41;
      font-size: 30px;
      font-weight: 600;
      margin-top: 30px;
    }
  }

  .TaxiLeftImage {
    width: 60%;
    margin: auto;
    margin-top: 15vh;

    @media only screen and (min-width: 768px) and (max-width: 999px) {
      width: 65%;
      margin: auto;
      margin-top: 10vh;
    }
  }
};
