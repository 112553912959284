* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

$lightGray: #5a5f7d;
$lightBlue: #2c99ff;
$DarkBlack: #272b41;
$Gray: #9299b8;
$green: #20c997;
$primaryColor: #f7b217;

.id {
  margin: 0px;
  font-size: 14px;
  letter-spacing: 0px;
  color: #5A5F7D;
}
.oneWayBadge {
  background-color: $lightBlue;
  color: white;
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 3px;
  opacity: 1;
  text-transform: uppercase;
}

.rountTripBadge {
  background-color: $DarkBlack;
  color: white;
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 3px;
  opacity: 1;
  text-transform: uppercase;
}
.verticalDot {
  color: $lightGray;
  cursor: pointer;
}
.customerName {
  font-size: 16px;
  color: $DarkBlack;
  letter-spacing: 0px;
  opacity: 1;
  margin: 0px;
  font-weight: bold;
}
.mobileNumber {
  font-size: 14px;
  color: #5A5F7D;
  letter-spacing: 0px;
  opacity: 1;
}
.contentHeadings {
  font-size: 12px;
  color: $Gray;
  margin-bottom: 0px;
}
.contentValue {
  font-size: 14px;
  color: $DarkBlack;
  letter-spacing: 0px;
  opacity: 1;
  font-weight: bold;
}

.btnDiv {
  margin: 5%;
  margin-top: 0;
}

.confirmBtn {
  width: 100%;
  border-radius: 3px;
  background-color: $DarkBlack;
  border: none;
  color: white;
  padding: 10px;
}

.assignBtn {
  width: 100%;
  border-radius: 3px;
  background-color: $primaryColor;
  border: none;
  color: white;
  padding: 10px;
}
.tripStartedBtn {
  width: 100%;
  border-radius: 3px;
  background-color: $DarkBlack;
  border: none;
  color: white;
  padding: 10px;
}
.completeTripBtn {
  width: 100%;
  border-radius: 3px;
  background-color: $green;
  border: none;
  color: white;
  padding: 10px;
}
.reviewLinkBtn {
  width: 100%;
  border-radius: 3px;
  background-color: $DarkBlack;
  border: none;
  color: white;
  padding: 10px;
}

.backStateDiv{
  cursor: pointer;
  padding: 0px 0px 10px 0px;
}

.modalDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 25px;

  .modalCross {
    border-radius: 100%;
    padding: 0 0.7rem 0.3rem;
    background-color: #f4f5f7;
    margin-bottom: 0.8rem;
    cursor: pointer;
    margin-left: 1rem;
    margin-top: 1rem;
  }

  .oneWay {
    color: #fff;
    font-size: 8px;
    font-weight: 600;
    padding: 4px 3px 3px 3px;
    border-radius: 3px;
    margin-right: -7%;
    background-color: #2c99ff;
    text-transform: uppercase;
  }

  .roundTrip {
    color: #fff;
    font-size: 10px;
    font-weight: 600;
    padding: 4px 3px 3px 3px;
    border-radius: 3px;
    background-color: #000;
    text-transform: uppercase;
  }
};

.idNumber {
  margin: 0;
  font-size: 12px;
  font-weight: 700;
  color: #5a5f7d;
};

.modalflex {
  display: flex;
  align-items: center;
};

@import url("https://cdn.syncfusion.com/ej2/material.css");

* {
  font-family: "Inter", sans-serif;
  letter-spacing: 0px;
}

.modalflex {
  display: flex;
  align-items: center;
}

.modalDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 25px;

  .modalCross {
    border-radius: 100%;
    padding: 0 0.7rem 0.3rem;
    background-color: #f4f5f7;
    margin-bottom: 0.8rem;
    cursor: pointer;
    margin-left: 1rem;
    margin-top: 1rem;
  }

  .oneWay {
    color: #fff;
    font-size: 8px;
    font-weight: 600;
    padding: 4px 3px 3px 3px;
    border-radius: 3px;
    margin-right: -7%;
    background-color: #2c99ff;
    text-transform: uppercase;
  }

  .roundTrip {
    color: #fff;
    font-size: 10px;
    font-weight: 600;
    padding: 4px 3px 3px 3px;
    border-radius: 3px;
    background-color: #000;
    text-transform: uppercase;
  }
}

.midSection {
  margin: auto;

  .name {
    > p:first-child {
      margin: 0;
      color: #272b41;
      font-weight: 500;
      font-size: 16px;
    }

    > p:last-child {
      margin: 0;
      color: #5a5f7d;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .data {
    width: 100%;
    margin-top: 10px;
    display: block;

    > p:first-child {
      margin: 0;
      font-size: 14px;
      color: #6e707f;
      font-size: 12px;
      font-weight: 400;
    }

    > p:last-child {
      margin: 0;
      color: #272b41;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .modalflexInnerPart {
    display: flex;
    width: 100%;
    gap: 40px;
    margin-top: 5px;
    text-align: left;

    .confirmbtn {
      padding: 0.5rem;
      margin-top: 10px;
      border-radius: 0.2rem;
      border: none;
      background: rgb(42, 41, 41);
      color: white;
    }
  }

  .bookingAmt {
    margin-top: 10px;
    display: block;

    > p:first-child {
      margin: 0;
      font-size: 14px;
      color: #6e707f;
      font-size: 12px;
      font-weight: 400;
    }

    > p:last-child {
      margin: 0;
      color: #20c997;
      font-weight: 700;
      font-size: 14px;
    }
  }

  .noData {
    display: none;
  }
}

.popupcontent {
  width: 350px !important;
  height: 300px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 4px 4px 70px 10px rgb(0, 0, 0/11%);
  border-radius: 10px;
  text-align: center;

  .imgbox {
    text-align: center;
    
    .CheckedIcon {
      width: 96px;
      height: 96px;
      margin-top: 15px;
    }
  }

  .sucessmessage {
    margin-top: 30px;
    color: #5A5F7D;
    font-size: 14px;
    font-weight: 500;
  }

  .dashboard {
    margin-top: 5px;
    font-size: 14px;
    color: #5A5F7D;
    font-weight: 400;
  }

  .button {
    margin-top: 20px;
    background-color: #F7B217 ;
    border-radius: 8px;
    width: 213px;
    height: 49px;
    font-size: 14px;
    color: #FFFFFF;
  }
};

.modalcancle {
  font-size: 14px;
  padding: 0px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #9299B83D;
  border-radius: 5px;
  color: #272B41;
  font-weight: 500;
  border: none;
}

.modalBody{
  padding: 0px 10px;
}

.backStateDiv{
   cursor: pointer;
   padding-left: 3px;
};

.assignedTo {
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: max-content;

  > img {
    width: 20px;
    height: 20px;
    border-radius: 20px;
  }

  > div {
    margin-left: 10px;

    > p:first-child {
      margin: 0;
      color: #272b41;
      font-size: 14px;
      font-weight: 500;
    }

    > p:last-child {
      margin: 0;
      color: #6e707f;
      font-size: 12px;
      font-weight: 400;
    }
  }
};

.notAssignedTo {
  display: none;
}

