* {
  font-family: "Inter", sans-serif;
  letter-spacing: 0px;
  font-size: 14px;
}

.mainContainer {
  padding: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 20px #9299b808;
}

.editProfileText {
  color: #272b41;
  font-size: 16px;
  font-weight: 600;
}

.peronalInformationText {
  font-size: 13px;
  color: #9299b8;
  font-weight: 500;
}

.userInformationDiv {
  margin: auto;
}

.inputbox {
  .inputlabel {
    font-size: 14px;
    color: #272b41;
    font-weight: 600;
  }
  .input {
    height: 43px;
    border: 1px solid #e3e6ef;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    padding: 0 0 0 1rem;
    &:focus {
      border: 0 !important;
    }
    ::placeholder {
      color: #5a5f7d;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.formDiv {
  margin: auto;
  padding: 40px 0px 20px 0px;
  width: 70%;
  .formInnerDiv {
    margin: auto;
  }
}

.buttonDiv {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  .updateButton {
    background: #f7b217 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 5px #20c9970d;
    color: #ffffff;
    font-size: 14px;
    padding: 10px;
    border-radius: 7px;
  }
  .updateButton:disabled{
     opacity: 0.5;
  }
}
