* {
  font-family: "Inter", sans-serif;
  letter-spacing: 0px;
}

.sidebar {
  width: 100%;
  opacity: 1;
  margin-top: 0;
  @media (min-width: 280px) and (max-width: 1023px) {
  }

  .sidebarElements {
    width: 100%;
    display: flex;
    padding: 5px 10px;
    margin: auto;
    align-items: space-between;
    border-radius: 4px;
    gap: 10px;
    cursor: pointer;

    .sidebarIcon {
      font-size: 20px;
      color: #adb4d2;
    }

    > p {
      margin: 0;
      color: #46474c;
      padding-left: 8px;
      font-size: 14px;
      font-weight: 400;
      font-family: "Inter", sans-serif;
    }
  }

  .sidebarElements:first-child {
    margin-top: 10px;
  }

  .sidebarElements:hover {
    background-color: #f7b217;
    .sidebarIcon,
    > p {
      color: #ffffff;
    }
  }
}
