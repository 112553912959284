* {
  font-family: "Inter", sans-serif;
  letter-spacing: 0px;
  font-size: 14px;
}

.mainContainer {
  background-color: #f4f5f7;
  .mainContainerInnerDiv {
    width: 100%;
    margin-left: 20%;
    padding-right: 20px;
    @media only screen and (max-width: 1023px) {
      margin-left: 0%;
      padding: 15px;
    }
  }
}

.myProfile {
  color: #272b41;
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
  padding-top: 20px;
  @media only screen and (max-width: 767px) {
    padding: 8px 0px;
  }
}
.EditInformationMainDiv {
  gap: 20px;
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
  .editLeftDiv {
    padding: 10px;
    min-width: 230px;
    max-height: 280px;
    width: 30%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 20px #9299b808;
    border-radius: 10px;
    @media only screen and (max-width: 767px) {
      width: 100%;
      // max-width: ;
    }
    .userInformation {
      text-align: center;
      .userName {
        margin-top: 10px;
        color: #272b41;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  .editRightDiv {
    width: 100%;
  }
}

.tabDiv {
  display: flex;
  padding: 5px 10px;
  align-items: center;
  .tabDivText {
    font-size: 14px;
    padding-left: 10px;
    color: #9299b8;
    cursor: pointer;
  }
}

.activeTab {
  display: flex;
  padding: 5px 10px;
  align-items: center;
  background: #fffbf3 0% 0% no-repeat padding-box;
  border-radius: 6px;
  .activetabText {
    padding-left: 10px;
    color: #f7b217;
    font-size: 14px;
    cursor: pointer;
  }
}

.rightDiv::-webkit-scrollbar {
  width: 10px;
  cursor: pointer;
}
/* Track */

.rightDiv::-webkit-scrollbar-track {
  background: #f1f1f1;
  cursor: pointer;
}

/* Handle */
.rightDiv::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
  cursor: pointer;
}

.rightDiv {
  height: 55vh;
  overflow-y: scroll;
  @media only screen and (max-width: 767px) {
    height: max-content;
  }
}

.backGroundImageStyle {
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.mobilebackGroundImageStyle {
  @media only screen and (min-width: 768px) {
    display: none;
  }
}
